import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './FinancialRegBG.jpg'
function FinancialRegulation() {
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
    <div className='centeblack'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#ffffff",fontSize:"100px", textAlign:"right" ,fontWeight:"bold", width:"10%"}} className="responsive64">Financial Regulations</h1>

    <center>

    <br></br>
    </center>
    </div>
<center>
    
<br></br>
    <br></br>
<table border="1" width="100%" height="31" cellspacing="0" cellpadding="0">
      <tbody><tr>
        <td>
          
<article id="post-121" class="post-121 page type-page status-publish has-post-thumbnail hentry">


<div class="entry-content">
  <div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-4"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/সিকিউরিটিজ-ও-এক্সচেঞ্জ-কমিশন-মিউচ্যুয়াল-ফান্ড-বিধিমালা-২০০১.pdf">সিকিউরিটিজ ও এক্সচেঞ্জ কমিশন (মিউচ্যুয়াল ফান্ড) বিধিমালা, ২০০১</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/সিকিউরিটিজ-ও-এক্সচেঞ্জ-কমিশন-মার্চেন্ট-ব্যাংকার-ও-পোর্টফোলিও-ম্যানেজার-বিধিমালা-১৯৯৬.pdf">সিকিউরিটিজ ও এক্সচেঞ্জ কমিশন (মার্চেন্ট ব্যাংকার ও পোর্টফোলিও ম্যানেজার) বিধিমালা, ১৯৯৬</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/সিকিউরিটিজ-ও-এক্সচেঞ্জ-কমিশন-স্টক-ডিলার-স্টক-ব্রোকার-ও-অনুমোদিত-প্রতিনিধি-বিধিমালা-২০০০.pdf">সিকিউরিটিজ ও এক্সচেঞ্জ কমিশন (স্টক-ডিলার, স্টক-ব্রোকার ও অনুমোদিত প্রতিনিধি) বিধিমালা, ২০০০</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/ডিপজিটরি-প্রবিধানমালা-২০০০.pdf">ডিপজিটরি প্রবিধানমালা, ২০০০</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/এক্সচেঞ্জেস-ডিমিউচ্যুয়ালাইজেশন-আইন-২০১৩.pdf">এক্সচেঞ্জেস ডিমিউচ্যুয়ালাইজেশন আইন, ২০১৩</a></strong></p>

  </div>
</div>
</div></div></div><div class="wpb_column vc_column_container vc_col-sm-4"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Bangladesh-Securities-and-Exchange-Commission-Alternative-Investment-Rules-2015.pdf">Bangladesh Securities and Exchange Commission (Alternative Investment) Rules, 2015</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Securities-and-Exchange-Commission-Rights-Issue-Rules-2006.pdf">Securities and Exchange Commission (Rights Issue) Rules, 2006</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/The-Securities-and-Exchange-Ordinance-1969.pdf">The Securities and Exchange Ordinance, 1969</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Securities-and-Exchange-Rules-1987.pdf">Securities and Exchange Rules, 1987</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Securities-and-Exchange-Commission-Over-theCounter-Rules-2001.pdf">Securities and Exchange Commission (Over-theCounter) Rules, 2001</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Margin-Rules-1999.pdf">Margin Rules, 1999</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Depositories-Act-1999.pdf">Depositories Act, 1999</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/CREDIT-RATING-COMPANIES-RULES-1996.pdf">CREDIT RATING COMPANIES RULES, 1996</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Bangladesh-Securities-and-Exchange-Commission-Public-Issue-Rules-2015.pdf">Bangladesh Securities and Exchange Commission (Public Issue) Rules, 2015</a></strong></p>
<p>&nbsp;</p>
<p><strong><a href="https://old.acaml.com/wp-content/uploads/2020/02/Bangladesh-Securities-and-Exchange-Commission-Exchange-Traded-Fund-Rules-2016.pdf">Bangladesh Securities and Exchange Commission (Exchange Traded Fund) Rules, 2016</a></strong></p>

  </div>
</div>
</div></div></div><div class="wpb_column vc_column_container vc_col-sm-4"><div class="vc_column-inner"><div class="wpb_wrapper"></div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper"><hr></hr>
  <h1><strong>Banking and Non-banking Rules</strong></h1>
 
  </div>
</div>
</div></div></div><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner"><div class="wpb_wrapper"></div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p><a href="https://old.acaml.com/wp-content/uploads/2020/02/Microcredit-Regulatory-Rules-2010.pdf">Microcredit Regulatory Rules, 2010</a></p>
<p><a href="https://old.acaml.com/wp-content/uploads/2020/02/Insurance-Corporations-Act-2019.pdf">Insurance Corporations Act, 2019</a></p>
<p><a href="https://old.acaml.com/wp-content/uploads/2020/02/Insurance-Act-2010.pdf">Insurance Act, 2010</a></p>
<p><a href="https://old.acaml.com/wp-content/uploads/2020/02/Financial-Institute-Act-1993.pdf">Financial Institute Act, 1993</a></p>
<p><a href="https://old.acaml.com/wp-content/uploads/2020/02/Bank-Company-Act-1991.pdf">Bank Company Act, 1991</a></p>
<p><a href="https://old.acaml.com/wp-content/uploads/2020/02/Anti-Money-Laundering-Rules-2015.pdf">Anti Money-Laundering Rules, 2015</a></p>

  </div>
</div>
</div></div></div><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner"><div class="wpb_wrapper"></div></div></div></div>
    </div>


</article>
        </td>
      </tr>
    </tbody></table>
</center>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

  </div>

  );

}  







export default FinancialRegulation;
