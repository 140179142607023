import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './MnVBG.jpg'
import { Divider } from 'antd';
function MissionAndVision() {
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
    <div className='centeblack'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#ffffff",fontSize:"100px", textAlign:"right" ,fontWeight:"bold", width:"10%"}} className="responsive64">Mission &
Vision</h1>

    <center>

    <br></br>
    </center>
    </div>
<center>
<br></br>
<br></br>
<br></br>
<Divider orientation="center"><h2 style={{color:"red"}}> 

Mission
</h2></Divider>
<h3 style={{textAlign: "center",width:"70%", lineHeight:"1.4"}}>
Our mission is to give you the best possible return for your money and secure your financial future. We utilize only the best local talents available and comply completely with international norms and standards at every step of the way. Every decision made is completely transparent and undertaken with total professionalism. The interest of our stakeholders is our foremost concern.

<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<Divider orientation="center"><h2 style={{color:"red"}}> 

Vision
</h2></Divider>

</h3>
<h3 style={{textAlign: "center",width:"70%", lineHeight:"1.4"}}>
Our vision is to establish Alliance Capital Asset Management Limited as the ultimate investment choice in Bangladesh. We want to develop a rich pool of research tools and strategies that will yield the best possible returns for our stakeholders and investors.



</h3>
</center>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

  </div>

  );

}  







export default MissionAndVision;
