import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './dpmBG.jpg'
import down from './down.svg'

function DPMPage() {
 
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
    <div className='centered'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#343a40", textAlign:"right" ,fontWeight:"bold", width:"10%"}} className="responsive64">Discretionary Portfolio Management</h1>

    <center>

    <img src={down} width="10%" alt="ImageAltedLoading" className="hoverDown"/>

    <br></br>
    </center>
    </div>
<div className='centered' id="hknws"  style={{backgroundColor:"#ffcb0550",backgroundRepeat:"no-repeat", backgroundSize:"100%",  padding: "5% 10%", height:"auto"}}>

    <center>
    <h1 style={{color:"#343a40" ,fontWeight:"bold", width:"60%"}} className="responsive64">Alliance Managed Portfolio</h1>

    </center>
    </div>


<br></br>
<br></br>
<h3 style={{textAlign: "justify", lineHeight:"2"}}>
Alliance Managed Portfolio (AMP) is a discretionary portfolio management service which emphasizes on a 

structured approach for institutional investors in capital market, where the investment decision making is 

delegated to a team of professional investment managers.The main objective is to create value through the 

active management of the portfolio, risk minimization and protection of capital in any economic condition.


</h3>
<br></br>
<br></br>
<hr></hr>
<br></br>
<br></br>
<br></br>
<br></br>
<div className='centered' id="hknws"  style={{backgroundColor:"#ffcb0550",backgroundRepeat:"no-repeat", backgroundSize:"100%",  padding: "5%", height:"auto"}}>

    <center>
      <br></br>
    <h1 style={{color:"#343a40" ,fontWeight:"bold", width:"60%"}} className="responsive64">Alliance Portfolio Advisory Services</h1>
  
    </center>
    </div>


<br></br>
<br></br>
<h3 style={{textAlign: "justify", lineHeight:"2"}}>
Alliance Portfolio Advisory Services is focused on reconstruction of existing portfolios by providing advisory

services on investing in securities such as stocks, bonds, mutual funds through an experienced portfolio 

management team.


</h3>

  </div>

  );

}  







export default DPMPage;
