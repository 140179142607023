import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import managementBG from './managementBG.jpg'
import down from './down.svg'
import { Row } from 'antd';
import ashiq from './Management/Ashiq.png'
import forhad from './Management/forhad.jpg'
import MD from './Management/MD.jpg'
import shajal from './Management/shajal.jpg'
import { Divider   } from 'antd';

function ManagementProfile() {



  var url = window.location.href;
url = url.split('#').pop().split('?').pop();
let pageURL = url.substring(url.lastIndexOf('/') + 1);
function sdighu(){
  if(pageURL !== "managementprofile"){

    document.querySelector("#khasadul").setAttribute("hidden", "")
    document.querySelector("#forhadrahman").setAttribute("hidden", "")
    document.querySelector("#ashiqrayhan").setAttribute("hidden", "")
    document.querySelector("#shajalmahamud").setAttribute("hidden", "")
    // document.querySelector(`#${pageURL}`).removeAttribute("hidden")
    document.querySelector(`#mainPPr`).innerHTML = document.querySelector(`#${pageURL}`).innerHTML
  
  
  }
}


  window.onload=()=>{
    sdighu()
    document.querySelector("#ftnt").remove()
  }

  return (
    <div className="App">
      
      <div className='centered' style={{backgroundImage:`url(${managementBG})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
      <center>
      <br></br>
      <br></br>
      <h1 style={{color:"#343a40", fontWeight:"bold"}} className="responsive64">Management Profile</h1>
      <img src={down} alt="ImageAltedLoading" width="10%" className="hoverDown"/>
  
      <br></br>
      <br></br>
      </center>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <center>
    <div style={{width:"90%"}}>
    <Row id="mainPPr">
</Row>
    <Row id="khasadul">
<img src={MD} width="auto%" style={{margin:"0 auto", }} alt="ImageAltedLoading"/>

  <h6 style={{textAlign:"justify"}}> 
  <Divider orientation="center"><h3>Kh. Asadul Islam</h3></Divider>
  <Divider orientation="center"><h5>Managing Director & CEO</h5></Divider>

Mr. Islam has years of experience in Bangladesh Capital Market, and has a proven track record of managing and leading financial institutions with innovation. Prior to his appointment as Managing Director & CEO with ACAML, he was the founding Managing Director & CEO of City Brokerage Limited, a wholly owned subsidiary of The City Bank Limited, Bangladesh. He also held position of Executive Vice President and Head of Brokerage of the City bank since July 2009. Mr. Islam also led IDLC Securities Limited as its Chief Executive Officer (CEO) and played a key role in setting up the company. He began his career with Premium Securities Limited and later moved to the renowned multinational investment bank Peregrine Capital Ltd followed by a shift to the Union Capital Limited (UCL). In 2002, he became the Director of SES Company Limited a wholly owned subsidiary of UCL. Mr. Islam carries the ability to support, encourage and motivate colleagues and investors locally and internationally, and holds an excellent track record in generating new business as well as managing high profile client relationship. Mr. Islam has vast experience of working in collaboration with numerous renowned Venture Capital firms in international level and established relationship with one of the top US based prime brokers, Auerbach Grayson and Company, LLC to bring foreign portfolio investment into Bangladesh. He is a member of different policy level committees in government regulatory level and he is also the Secretary General of Association of Asset Management Companies and Mutual Fund (AAMCMF) in Bangladesh. </h6>
</Row>
<br></br><br></br><br></br>
<br></br>
<br></br><br></br>
<br></br>

 <br></br><br></br><br></br>

<br></br>
<Row id="ashiqrayhan">
<img src={ashiq} width="auto%" style={{margin:"0 auto", }} alt="ImageAltedLoading"/>

  <h6 style={{textAlign:"justify"}}> <Divider orientation="center"><h3>Ashiq Rayhan
</h3></Divider> 
  <Divider orientation="center"><h5>VP & Head of Research

</h5></Divider>

  
Mr. Rayhan has more than nine years of experience in the capital market of Bangladesh. Before joining to Alliance Capital Asset Management Limited, he was the Head of Research of Sigma Capital Management Limited. Mr. Rayhan started his career at City Brokerage Limited (Subsidiary of The City Bank Limited) and worked there for five years. He completed his MBA from the University of Dhaka (Major in Finance) and his BBA (Major in Finance &Accounting) from North South University, Bangladesh. Mr. Rayhan received the prestigious Cum Laude honor from North South University, Bangladesh.
  </h6>
</Row>
  <br></br><br></br><br></br>
<br></br><br></br><br></br>

<Row id="forhadrahman">
<img src={forhad} width="auto%" style={{margin:"0 auto", }} alt="ImageAltedLoading"/>

  <h6 style={{textAlign:"justify"}}>
  <Divider orientation="center"><h3>Md. Forhad Rahman</h3></Divider>
  <Divider orientation="center"><h5>AVP - Company Secretary & Head of Human Resource</h5></Divider>

  Mr. Rahman has been serving Alliance Capital Asset Management Limited for more than five years. He graduated from the University of Dhaka in English. Previously, he was employed at Pearl Institute of Financial Market Limited (PiFM). Mr. Rahman is a student of Executive Level III of Institute of Chartered Secretary of Bangladesh (ICSB).
   </h6>
</Row>

<br></br><br></br><br></br>
<br></br>
<br></br><br></br>
<br></br>
  <Row id="shajalmahamud">
<img src={shajal} width="auto%" style={{margin:"0 auto", }} alt="ImageAltedLoading"/>

  <h6 style={{textAlign:"justify"}}>
  <Divider orientation="center"><h3>Md. Shajal Mahamud</h3></Divider>
  <Divider orientation="center"><h5>Senior Manager-Accounts & Finance

</h5></Divider>
  Mr. Mahamud has more than six years of experience as a Professional Accountant. He has knowledge and expertise in Audit Services and Accounting & Financial Management. Mr. Mahamud has both of his BBA and MBA degree majoring in Accounting from Bangladesh University of Business and Technology (BUBT). He is currently perusing for CA Professional Examination under the institute of Chartered Accountants of Bangladesh (ICAB).
  </h6>
</Row>
<br></br><br></br><br></br>
<br></br>
<br></br><br></br>
<br></br>


<br></br><br></br><br></br>
    </div>
    </center>






    </div>
  );

}  







export default ManagementProfile;
