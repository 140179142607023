import {React, 
    useState} from 'react';
import logoBig from './logoBig.svg';
import search from './search.svg';
import menu from './menu.svg';
import './App.css';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarToggler,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBNavbarNav,
  } from 'mdb-react-ui-kit';

export default function NavBar() {


let hostname = window.location.hostname
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.backgroundColor = "#ffffff00";
    document.getElementById("navbar").style.top = "0";
  }else {
    document.getElementById("navbar").style.top = "-160px";
    document.getElementById("navbar").style.backgroundColor = "#ffffff";
    document.querySelectorAll(".borderBottomText").color = "#171C24 !important ";
  
  }
  
  if(window.pageYOffset > 100){
    document.getElementById("navbar").style.backgroundColor = "#000000";
  
  }
  
  if(window.pageYOffset < 100){
    document.getElementById("navbar").style.backgroundColor = "#ffffff00";
  
  
  }
  
    prevScrollpos = currentScrollPos;
  }
      

    const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
  return (
   <div className="fixed-top"  id="navbar" >
  
        <MDBNavbar light bgColor='light '  >
      <MDBContainer fluid>
        <a className='navbar-brand' href={"home"}>
            <img src={logoBig} style={{width:70+"%"}} alt="ImageAltedLoading" />
        </a>
        <form className='d-flex input-group w-auto'  style={{marginTop:"5px", justifyContent:"end", justifyItems:"end", textAlign:"end"}}>
          <input type='search' size={15} className='form-control' placeholder='Search' aria-label='Search' />
          <button className="btn-transparent">
              <img src={search}  alt="ImageAltedLoading"/>
          </button>
        </form>
      </MDBContainer>
    </MDBNavbar>
   
    <MDBNavbar expand='lg'  light bgColor='light'   >
      
        <MDBContainer fluid >
          <MDBNavbarToggler
            type='button'
            data-target='#navbarTogglerDemo02'
            aria-controls='navbarTogglerDemo02'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
          >
                       <img src={menu} alt="ImageAltedLoading"/>

          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNavNoTogglerSecond} style={{backgroundColor:"#FBFBFB", padding:"20px 0px"}}>
            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0' style={{ padding:"20px"}}>
           
           
              <MDBNavbarItem>
                <MDBNavbarLink  href='home'>Home</MDBNavbarLink>
              </MDBNavbarItem>
                
      <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>



              <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link'  >
                  Services
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='dmpPage'>Discretionary Portfolio Management</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='capage'>Corporate Advisory</MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
      <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>
           
              <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link'  >
                  Mutual Fund
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='mtbunitfund'>MTB Unit Fund</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='alliancesandhanilifeunitfund'>Alliance Sandhani Life Unit Fund</MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>
           
              <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link'  >
                  About Us
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover"href='whychooseAcaml'>Why Choose ACAML</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='managementprofile'>Management Profile</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='missionandvision'>Mission & Vision</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='latestnews'>Latest News</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='financialregulation'>Financial Regulation</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='interviewsinmedia'>Interviews In Media</MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
            <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>
           
              <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link'  >
                  Bangladesh
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='economyataglance'>Economy at a glance</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='financialsystem'>Financial System</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='financialregulators'>Financial Regulators</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='securitiesmarket'>Securities Market</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink   className="navHover" href='whychoosebangladesh'>Why Choose Bangladesh</MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>

            <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink  href='reports'>Reports</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
            
            </MDBNavbarItem>
            <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>

            <MDBNavbarItem>
                <MDBNavbarLink  href='career'>Career</MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>




              <MDBNavbarItem>
                <MDBNavbarLink  href='portal'>Investor Portal</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>


              <MDBNavbarItem>
                <MDBNavbarLink  href='AadminA'>Admin</MDBNavbarLink>
              </MDBNavbarItem>
         
              <MDBNavbarItem>
      <div style={{width: '13px', height: '80%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
      </div>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
   </div>
  );
}