import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

function IReport() {

  var w = window.innerWidth;
  var h = window.innerHeight;


  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (
    <div className="App">
     <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
<center>

<iframe title="INTERACTION" width={(w-100)} height={h} src="https://app.powerbi.com/view?r=eyJrIjoiNWY3MGEwMzktNDcxYS00OTk4LWJmYmYtYzVjZjNmYjk3NWQ4IiwidCI6IjViOTFkMDU5LWRlZDQtNGE0MC05OGY3LTlkZWJlNzI3MTViNyIsImMiOjEwfQ%3D%3D&pageName=ReportSection" frameborder="0" allowFullScreen="true"></iframe>
  </center>    </div>
  );

}  




export default IReport;
