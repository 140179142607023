





function EconomyAtAGlance(){

    window.onload=()=>{
          document.querySelector("#ftnt").remove()
        }
      return (
    <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1>Economy At A Glance</h1>
        <hr></hr>
        <div class="entry-content">
  <div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p><strong>Central Bank</strong></p>
<p>Bangladesh Bank acts as the Central Bank of Bangladesh which was established on December 16, 1971 through the enactment of Bangladesh Bank Order 1972- President’s Order No. 127 of 1972 (Amended in 2003). The general superintendence and direction of the affairs and business of BB have been entrusted to a 9 members’ Board of Directors which is headed by the Governor who is the Chief Executive Officer of this institution as well. BB has 40 departments and 9 branch offices. In Strategic Plan (2010-2014), the vision of BB has been stated as, “To develop continually as a forward looking central bank with competent and committed professionals of high ethical standards, conducting monetary management and financial sector supervision to maintain price stability and financial system robustness, supporting rapid broad based inclusive economic growth, employment generation and poverty eradication in Bangladesh”</p>
<p>The main functions of BB are (Section 7A of BB Order, 1972)</p>
<ul>
<li>to formulate and implement monetary policy;</li>
<li>to formulate and implement intervention policies in the foreign exchange market;</li>
<li>to give advice to the Government on the interaction of monetary policy with fiscal and exchange rate policy, on the impact of various policy measures on the economy and to propose legislative measures it considers necessary or appropriate to attain its objectives and perform its functions;</li>
<li>to hold and manage the official foreign reserves of Bangladesh;</li>
<li>to promote, regulate and ensure a secure and efficient payment system, including the issue of bank notes;</li>
<li>to regulate and supervise banking companies and financial institutions.</li>
</ul>

  </div>
</div>
</div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-9"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p><strong>Regulator of Capital Market Intermediaries</strong></p>
<p>Bangladesh Securities and Exchange Commission (BSEC) performs the functions to regulate the capital market intermediaries and issuance of capital and financial instruments by public limited companies. It was established on June 8, 1993 under the Bangladesh Securities and Exchange Commission Act, 1993. A 5 member commission headed by a Chairman has the overall responsibility to administer securities legislation and the Commission is attached to the Ministry of Finance.</p>

  </div>
</div>
</div></div></div><div class="wpb_column vc_column_container vc_col-sm-3"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_single_image wpb_content_element vc_align_left">
  
  <figure class="wpb_wrapper vc_figure">
    <a href="http://www.sec.gov.bd/"  class="vc_single_image-wrapper   vc_box_border_grey"><img  height="150" src="https://www.sec.gov.bd/images/banner_white2.png" class="vc_single_image-img attachment-thumbnail" alt=""/></a>
  </figure>
</div>
</div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p>The mission of BSEC is to protect the interests of securities investors, to develop and maintain fair, transparent and efficient securities markets and to ensure proper issuance of securities and compliance with securities laws. The main functions of BSEC are:</p>
<ul>
<li>Regulating the business of the Stock Exchanges or any other securities market.</li>
<li>Registering and regulating the business of stock-brokers, sub-brokers, share transfer agents, merchant bankers and managers of issues, trustee of trust deeds, registrar of an issue, underwriters, portfolio managers, investment advisers and other intermediaries in the securities market.</li>
<li>Registering, monitoring and regulating of collective investment scheme including all forms of mutual funds.</li>
<li>Monitoring and regulating all authorized self regulatory organizations in the securities market.</li>
<li>Prohibiting fraudulent and unfair trade practices in any securities market.</li>
<li>Promoting investors’ education and providing training for intermediaries of the securities market.</li>
<li>Prohibiting insider trading in securities.</li>
<li>Regulating the substantial acquisition of shares and take-over of companies.</li>
<li>Undertaking investigation and inspection, inquiries and audit of any issuer or dealer of securities, the Stock Exchanges and intermediaries and any self regulatory organization in the securities market.</li>
<li>Conducting research and publishing information.</li>
</ul>

  </div>
</div>
</div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p><strong>Insurance Authority</strong></p>
<p><a href="http://www.idra.org.bd/idra-org/index.htm"><img class="alignright size-full wp-image-155" src="http://www.idra.org.bd/themes/responsive_npf/img/logo/idra.png" alt="" width="300" height="150"/></a>Insurance Development and Regulatory Authority (IDRA) was instituted on January 26, 2011 as the regulator of insurance industry being empowered by Insurance Development and Regulatory Act, 2010 by replacing its predecessor, Chief Controller of Insurance. This institution is operated under Ministry of Finance and a 4 member executive body headed by Chairman is responsible for its general supervision and direction of business. IDRA has been established to make the insurance industry as the premier financial service provider in the country by structuring on an efficient corporate environment, by securing embryonic aspiration of society and by penetrating deep into all segments for high economic growth. The mission of IDRA is to protect the interest of the policy holders and other stakeholders under insurance policy, supervise and regulate the insurance industry effectively, ensure orderly and systematic growth of the insurance industry and for matters connected therewith or incidental thereto.</p>

  </div>
</div>
</div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner"><div class="wpb_wrapper">
<div class="wpb_text_column wpb_content_element ">
  <div class="wpb_wrapper">
    <p><strong>Regulator of Micro Finance Institutions</strong></p>
<p><a href="http://www.mra.gov.bd/"><img class="alignright size-full wp-image-156" src="https://th.bing.com/th/id/R.8af8d165b56f02ea1588f712474a64c2?rik=t1%2fmprMzhNL9%2fA&riu=http%3a%2f%2fmra.teletalk.com.bd%2fapplicant%2fimages%2fmra_logo.jpg&ehk=oS4l9XkGe383vmzLWzga%2fEKFFFhH0qTlH2WWQUNtTHk%3d&risl=&pid=ImgRaw&r=0" alt="" width="300" height="150"/></a>To bring Non-government Microfinance Institutions (NGO-MFIs) under a regulatory framework, the Government of Bangladesh enacted “Microcredit Regulatory Authority Act, 2006’” (Act no. 32 of 2006) which came into effect from August 27, 2006. Under this Act, the Government established Microcredit Regulatory Authority (MRA) with a view to ensuring transparency and accountability of microcredit activities of the NGO-MFIs in the country. The Authority is empowered and responsible to implement the said act and to bring the microcredit sector of the country under a full-fledged regulatory framework. MRA’s mission is to ensure transparency and accountability of microfinance operations of NGO-MFIs as well as foster sustainable growth of this sector.</p>

  </div>
</div>
</div></div></div></div>
    </div>
    </div>
      );
    
}

export default EconomyAtAGlance