import pic from './FinancialSystem.jpg'





function FinancialSystem(){

    window.onload=()=>{
          document.querySelector("#ftnt").remove()
        }
      return (
    <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1>Financial System</h1>
        <hr></hr>
        <p>
        The financial system of Bangladesh is comprised of three broad fragmented sectors:
        </p>
        <ul>
            <li>
                <p>
                Formal Sector
                </p>
            </li>
            <li>
                <p>
                Semi-Formal Sector
                </p>
            </li>
            <li>
                <p>
                Informal Sector
                </p>
            </li>
        </ul>
        <br></br>
        <br></br>
<p>
The sectors have been categorized in accordance with their degree of regulation. The formal sector includes all regulated institutions like Banks, Non-Bank Financial Institutions (FIs), Insurance Companies, Capital Market Intermediaries like Brokerage Houses, Merchant Banks etc.; Micro Finance Institutions (MFIs).
</p>
<p>
The semi formal sector includes those institutions which are regulated otherwise but do not fall under the jurisdiction of Central Bank, Insurance Authority, Securities and Exchange Commission or any other enacted financial regulator. This sector is mainly represented by Specialized Financial Institutions like House Building Finance Corporation (HBFC), Palli Karma Sahayak Foundation (PKSF), Samabay Bank, Grameen Bank etc., Non Governmental Organizations (NGOs and discrete government programs. The informal sector includes private intermediaries which are completely unregulated.
</p>
        <center>
            <img alt="Financial System of Bangladesh" src={pic}/>
        </center>
    </div>
      );
    
}

export default FinancialSystem