
import React from 'react'
import 'antd/dist/antd.css';
import {   Divider   } from 'antd';
import {   viewPDF   } from './firebaseC';




window.onload=()=>{
 
    document.querySelector("#ftnt").remove()
  }

class Dmrlatest extends React.Component {
    render() {
      return(
<div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
<Divider> <h1> Latest Daily Market Review</h1></Divider>

          
<div onLoad={viewPDF(this.props.name, "dmrkrisp", 1 )} id="dmrkrisp"></div>
    
</div>
      )

    }
  }


  export default Dmrlatest