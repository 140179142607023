import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './WCABG.jpg'
import wca1 from './wca1.jpg'
import wca2 from './wca2.jpg'
import wca3 from './wca3.jpg'
import wca4 from './wca4.jpg'
import wca5 from './wca5.jpg'
import { Avatar, Image, Divider } from 'antd';
function WhychooseAcaml() {
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
    <div className='centeblack'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#ffffff",fontSize:"100px", textAlign:"right" ,fontWeight:"bold", width:"10%"}} className="responsive64">Why Choose ACAML</h1>

    <center>

    <br></br>
    </center>
    </div>
<center>
<br></br>
<br></br>
<br></br>
<h3 style={{textAlign: "center",width:"70%", lineHeight:"1.4"}}>
At ACAML, our investors are not our clients, they are our business partners. We feel their 

joy and pain as much as they do! Therefore, we manage their fund with the highest level of

 care so that we can co-create their future. We mean Alliance for a better financial future! 

The key competencies that make us so special in Bangladesh are:

</h3>

<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
</center>
<center>
<Avatar  size={240} style={{marginRight:"5%"}} 
      src={<Image width="100%"  src={wca1} />}
    />
</center>
<Divider orientation="center"><h2 style={{color:"black"}}> 

    Dynamic Portfolio Management

</h2></Divider>

<h4 style={{padding:"5%", textAlign:"justify"}}>
Selecting good stocks is half the job done for portfolio management. Therefore, our restless effort with all our proprietary capabilities, analytics, models, tools and techniques are put into effort to identify the best combination of stocks in a portfolio in continuous manner. We chose composition of stocks that ensure “market beating” return and risk.

</h4>
<br></br>
<br></br>
<br></br>
<br></br>




<center>
  
<Avatar  size={240} style={{marginRight:"5%"}} 
      src={<Image width="100%"  src={wca2} />}
    />
</center>

<Divider orientation="center"><h2 style={{color:"black"}}> 

Proprietary Research Complemented by Market Intelligence

</h2></Divider>

<h4 style={{padding:"5%", textAlign:"justify"}}>
We have our own, wining analytics, models, tools and techniques that are proven to be successful and better than anyone in the industry. We publish world class research reports of which some are exclusively for our clients, and some are for all. We know Market Intelligence is one of the key factors in less efficient markets like Bangladesh, we have developed our own channels and methods of gathering continuous market information on what’s happening with competitors, investors, and the companies.

</h4>
<br></br>
<br></br>
<br></br>
<br></br>




<center>
<Avatar  size={240} style={{marginRight:"5%"}} 
      src={<Image width="100%"  src={wca3} />}
    />

</center>


<Divider orientation="center"><h2 style={{color:"black"}}> 

Inefficient Market, Efficient Team

</h2></Divider>


<h4 style={{padding:"5%", textAlign:"justify"}}>
We have an extraordinary team of investment analysts who know their job! They have long experience, world class skill set, and relevant education that make them the very best in the industry. Most of the team players are Bangladeshi, knows the local economy and culture that make them fit for local fund management. They know the level of inefficiency of Bangladesh stock market and therefore better know how to maximize the benefit out of it. Our analyst and fund managers never try to be STARS themselves but try to make our clients so.

</h4>
<br></br>
<br></br>
<br></br>
<br></br>


<center>
<Avatar  size={240} style={{marginRight:"5%"}} 
      src={<Image width="100%"  src={wca4} />}
    />
</center>


<Divider orientation="center"><h2 style={{color:"black"}}> 

Long Term Investment Horizon

</h2></Divider>

<h4 style={{padding:"5%", textAlign:"justify"}}>
We buy and hold, add on the dips and take profits on price rises. This reduces transaction costs and keeps portfolios objective. We rarely pursue short-term returns for mainstream equity funds;however sometimes do not forget to tap a substantial opportunity.

</h4>
<br></br>
<br></br>
<br></br>
<br></br>



<center>
<Avatar  size={240} style={{marginRight:"5%"}} 
      src={<Image width="100%"  src={wca5} />}
    />
</center>


<Divider orientation="center"><h2 style={{color:"black"}}> 

Knowing Behavior and Psychology of the Market

</h2></Divider>


<h4 style={{padding:"5%", textAlign:"justify"}}>
Inefficient markets are full of opportunities. We have a specialist team of researchers that conducts behavioral studies and monitor the behavioral aspects of both individual and institutional investors. The team explores the sectors and stocks that are preferred with confidence by the investors on a forward call basis that helps us in finally selecting stocks for our portfolio.b

</h4>
<br></br>
<br></br>
<br></br>
<br></br>



  </div>

  );

}  







export default WhychooseAcaml;
