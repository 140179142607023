
import React from 'react'
import 'antd/dist/antd.css';
import {   Divider   } from 'antd';
import {   viewSpecPDF   } from './firebaseC';
import { Card } from 'antd';
import {changePage} from './changePage.js'
import { List, Avatar } from 'antd';
import {DatabaseTwoTone} from '@ant-design/icons';


const gridStyle = {
    width: '25%',
    textAlign: 'center',
  };


window.onload=()=>{
 
    document.querySelector("#ftnt").remove()
  }

  const data = [
    {
      title: 'Macro Reports',
      link: 'macroreports',
      description: 'An outlook about the wide economy'
    },
    {
      title: 'Company Notes',
      link: 'companynotes',
      description: 'Notes: listed companies'

    },
    {
      title: 'Daily Market Review',
      link: 'dailymarketreview',
      description: 'The dynamics of the stock market'

    },
    {
      title: 'Weekly Economic Indicator',
      link: 'weeklyeconomicindicator',
      description: 'Insider reports of every week'

    },
    {
      title: 'Alliance Fortnightly Insight',
      link: 'alliancefortnightlyinshight',
      description: 'Insider reports on every 14 working days'

    },
    {
      title: 'IPO Notes',
      link: 'iponotes',
      description: 'Notes: initial public offering'

    },
    {
      title: 'Budget',
      link: 'budget',
      description: 'Reports on country budget'

    },
    
   
  ];

class Reports extends React.Component {
    render() {
      return(
<div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
<Divider> <h1>Reports</h1></Divider>

<List
      itemLayout="horizontal"
      style={{margin:"4%"}}
      dataSource={data}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
          avatar={<DatabaseTwoTone />}
            title={<a href={item.link}>{item.title}</a>}
            description={item.description}
          />
        </List.Item>
      )}
    />

  
<div></div>
    
</div>
      )

    }
  }


  export default Reports