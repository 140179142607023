import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { getTheBuckets} from './firebaseC'

import bg1 from './BG/bg2.jpg'
import { Divider, Skeleton   } from 'antd';
import {  viewPDF , GetNAVBucket  } from './firebaseC'
import logoBig from './logoBig.svg';
function MTBUnitFund() {
 
    window.onload=()=>{
    getTheBuckets("fs")   

      viewPDF("MTB Downloads", "mtbD")
      viewPDF("MTB Financial Statement", "mtbFS")
      viewPDF("MTB Dividend Declaration", "mtbDD")
      viewPDF("MTB Quarterly Portfolio Statement", "mtbQPS")
      GetNAVBucket()
      document.querySelector("#ftnt").remove()
    }
  return (
    <div className="App" style={{padding:"1%"}}>
    <br></br><br></br>
<br></br>
<div className='centered'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#343a40", textAlign:"right" ,fontWeight:"bold", width:"60%"}} className="responsive64">MTB Unit Fund</h1>

    <center>

    <br></br>
    </center>
    </div>
      <div style={{padding:"20px"}}>      
<br></br>
<h2>
  About
</h2>
<h5 style={{textAlign: "justify", lineHeight:"2"}}>
Open-end unit fund has gained popularity among individual and institutional investors worldwide as it provides them the opportunity to actually invest in handpicked stocks that are selected by the investment managers after thoroughly researching companies, industries and markets. Unit funds always follow long-term investment strategies. Anyone who has investment in the market, or is willing to invest can consider investing in this fund as it gives the opportunity to diversify one’s own portfolio.

Keeping in mind the world-wide popularity and performance of the open-end funds and their substantial increase in number in recent years, Alliance Capital Asset Management Limited with the sponsorship of Mutual Trust Bank Limited took the initiative to launch this Fund in our market. This initiative is expected to contribute to the development of the capital market by providing a value added option for the investors.

The parties to the Fund are: Mutual Trust Bank Limited as the Sponsor, Alliance Capital Asset Management Limited as the Asset Manager, Bangladesh General Insurance Company Limited as the Trustee and BRAC Bank Limited as the Custodian of the Fund.
</h5>
<br></br>
<h2>
Fund Details
</h2>
<h5 style={{textAlign: "justify", lineHeight:"2"}}>
Sponsor: Mutual Trust Bank Limited
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Trustee: Bangladesh General Insurance Company Limited (BGIC)
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Custodian: BRAC Bank Limited
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Asset Manager: Alliance Capital Asset Management Limited (ACAML)
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Nature: Open-end Mutual Fund
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Size of Fund: Taka 100 crore
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Unit Price and Market Lot: Tk. 10.00 per unit, 500 units per lot
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Auditor: S. F. Ahmed & Co.
</h5>
<br></br>
<br></br>
<br></br>

<Divider><h3>NAV</h3></Divider>
<div>
        <center>
          <div style={{fontFamily: 'garamond'}}>
            <div style={{backgroundColor: '#ffcb05', padding: '10px'}}>
              <h3>Alliance Capital Asset Management Ltd (ACAML)</h3>
            </div>
            NAV of the Unit Funds Managed by ACAML
            <table style={{textAlign:"center", width: '100%', fontSize:"large"}}>
              <tbody><tr style={{border:"1px solid #ffcb05"}}>
                  <th style={{border:"1px solid #ffcb05"}}>
                    Name of the Fund
                  </th>
                  <th style={{border:"1px solid #ffcb05"}}>
                    NAV/Unit
                  </th>
                  <th style={{border:"1px solid #ffcb05"}}>
                    Sale Price/Unit
                  </th>
                  <th>
                    Repurchase Price/Unit
                  </th>
                </tr>
                <tr style={{border:"1px solid #ffcb05"}}>
                  <td>MTB Unit Fund</td>
                  <td style={{border:"1px solid #ffcb05"}} id="MTBNAV" />
                  <td style={{border:"1px solid #ffcb05"}} id="MTBSP" />
                  <td style={{border:"1px solid #ffcb05"}} id="MTBRP" />
                </tr>
                <tr style={{border:"1px solid #ffcb05"}}>
                  <td>Alliance Sandhani Life Unit Fund*</td>
                  <td style={{border:"1px solid #ffcb05"}} id="ANAV" />
                  <td style={{border:"1px solid #ffcb05"}} id="ASP" />
                  <td  style={{border:"1px solid #ffcb05"}}id="ARP" />
                </tr>
                <tr />
              </tbody></table>
            <p>*This fund is previously known as 'Sandhani Life Unit Fund'. The new name of the fund has come into effect from january 30, 2019 after the change of AMC approved by the BSEC</p>
            <p id="validation" />
          </div>
        </center>
        <div className="row">
          <div className="col-md-5">
            <img src={logoBig} width="40%" alt="" />
          </div>
          <div className="col-md-5" style={{marginLeft: '160px'}}>
            <div className="row">
              <div className="col-md-4">    <div style={{width: 'auto', height: '100%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
                </div>
              </div>
              <div className="col">www.acaml.com</div>
              <div className="col">  <div style={{width: '13px', height: '100%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
                </div></div>
            </div>
            <br />
            <p>T: +88 02 9121360 | E: info@acaml.com</p>
          </div>
        </div>
      </div>
<br></br>
<br></br>
<br></br><div id="mtbChart"  style={{height:"auto", transform:"scale(1.0)"}}><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
<br></br>
<br></br>
<Divider><h3>Downloads</h3></Divider>
<div id="mtbD"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>

<br></br>
<br></br>

<Divider><h3>Financial Statements</h3></Divider>
<div id="mtbFS"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
<Divider><h3>Dividend Declaraion</h3></Divider>
<div id="mtbDD"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
<Divider><h3>Quarterly Portfolio Statement</h3></Divider>
<div id="mtbQPS"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
   
      </div>
    </div>
  );

}  







export default MTBUnitFund;
