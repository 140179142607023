import React from 'react';
import { MDBFooter, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import toPortal from './toPortal.svg';
export default function Footer() {
  return (
    <MDBFooter backgroundcolor='light' style={{width:"100%"}} className='text-center text-lg-left shadow-2-strong' sticky='bottom' >
           <div>
             <br></br>
             <p id="loginLog"></p>
             <p>Looking for something specific?</p>
             <p></p>
             <hr></hr>
           </div>
       <div style={{padding:"20px"}}>
       <MDBRow style={{width:"100%"}}>
          <MDBCol lg='3' md='12' className='mb-4 mb-md-0'  style={{textAlign:"justify"}}>
            <h5 className='text-uppercase'>
            <i className="fas fa-map me-3"></i>     MAP
            </h5>

            <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.7625300252294!2d90.37270101541485!3d23.7558461944843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b89663b1c731%3A0x9a3a664e874b350d!2sAlliance%20Capital%20Asset%20Management%20Limited!5e0!3m2!1sen!2sbd!4v1627333253900!5m2!1sen!2sbd" width="300" height="250"  allowFullScreen="" loading="lazy"></iframe>
          </MDBCol>
       

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0' style={{textAlign:"justify"}}>
            <h5 className='text-uppercase mb-0'>Your Investments </h5>
            <img src={toPortal} alt="ImageAltedLoading"/>
          </MDBCol>
          <MDBCol lg='3' md='6' className='mb-4 mb-md-0' style={{textAlign:"justify"}}>
            <h5 className='text-uppercase mb-0'>USEFUL LINKS </h5>

            <ul className='list-unstyled'>
              <li  style={{margin:"15px 0px"}}>
                <a href='https://www.portal.acaml.com' className='text-dark'>
                <i className="fas fa-coins me-3"></i> Investor portal
                </a>
              </li>
              <li  style={{margin:"15px 0px"}}>
                <a href='https://dsebd.org' className='text-dark'>
                <i className="fas fa-link me-3"></i>  Dhaka Stock Exchange
                </a>
              </li>
              <li  style={{margin:"15px 0px"}}>
                <a href='https://www.amarstock.com/' className='text-dark'>
                <i className="fas fa-link me-3"></i>  AMARSTOCK
                </a>
              </li>
              <li  style={{margin:"15px 0px"}}>
                <a href='https://stockbangladesh.com/' className='text-dark'>
                <i className="fas fa-link me-3"></i>  Stock Bangladesh
                </a>
              </li>
              <li  style={{margin:"15px 0px"}}>
                <a href='https://thefinancialexpress.com.bd/' className='text-dark'>
                <i className="fas fa-link me-3"></i>  Financial Express
                </a>
              </li>
        
            </ul>
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0' style={{textAlign:"justify"}}>
            <h5 className='text-uppercase mb-0'>CONTACT</h5>

            <ul className='list-unstyled'>
              <li  style={{margin:"15px 0px"}}>
                <a className='text-dark' href="https://www.google.com/maps?ll=23.755841,90.37489&z=15&t=m&hl=en&gl=BD&mapclient=embed&cid=11113307517953455373">
                <i className="fas fa-home me-3"></i> Sima Blossom (10th Floor), Plot # 390 (Old)/03 (New), Road # 27 (Old)/16 (New), Dhanmondi C/A, Dhaka-1209, Bangladesh
                </a>
              </li>
              <li  style={{margin:"15px 0px"}}>
                <a href='mailto:info@acaml.com' className='text-dark'>
                <i className="fas fa-envelope me-3"></i>  info@acaml.com
                </a>
              </li>
              <li  style={{margin:"15px 0px"}}>
                <a href='tel:+8802222240740' className='text-dark'>
                <i className="fas fa-phone me-3"></i>  +88 02 2222 40740
                </a>
              </li>
              <li  style={{margin:"15px 0px"}}>
                <a className='text-dark' href="tel:+880222240647">
                <i className="fas fa-print me-3"></i>   +88 02 2222 40647
                </a>
              </li>
        
            </ul>
          </MDBCol>
        </MDBRow>
       </div>

      <div className='text-center p-3' style={{ backgroundcolor: 'rgba(0, 0, 0, 0.1)' }}>
        &copy; {new Date().getFullYear()}{' '}
        <a className='text-dark' href='https://acaml.com/'>
          Alliance Capital Asset Managemet Limited
        </a>
      </div>
    </MDBFooter>
  );
}