import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import 'antd/dist/antd.css'
import Dmrlatest from './latestDMR.js';
import NavBar from './NavBar';
import IReport from './IReport.js';
import Reports from './reportsPage.js';
import Footer from './Footer';
import SecuritiesMarket from './SecuritiesMarket';
import WhyChooseBangladesh from './WhyChooseBangladesh';
import DPMPage from './DPMPage';
import DmrReport from './DmrReport';
import CAPage from './CAPage';
import Career from './Career';
import EconomyAtAGlance from './EconomyAtAGlance.js';
import FinancialSystem from './FinancialSystem.js';
import FinancialRegulators from './FinancialRegulators.js';
import ReportPage from './ReportPage';
import MissionAndVision from './MissionAndVision';
import FinancialRegulation from './FinancialRegulation';
import InterviewsInMedia from './InterviewsInMedia';
import ManagementProfile from './ManagementProfile';
import AdminPage from './Admin'
import NAVPage from './navPage'
import MTBUnitFund from './MTBUnitFund';
import AllianceSandhaniLifeUnitFund from './AllianceSandhaniLifeUnitFund';
import WhychooseAcaml from './whychooseAcaml';
// import ChatBot from './chatBot';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import DuaMahfil from './duamahfil15';


ReactDOM.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>,
  document.getElementById('footer')
);

ReactDOM.render(
  <React.StrictMode>
    <NavBar />
  </React.StrictMode>,
  document.getElementById('navBar')
);

ReactDOM.render(
  
  <React.StrictMode>
<div>
<p>Loading</p>

</div>
  </React.StrictMode>,
  document.getElementById('ftnt')
);


//Get URL 
let urlArr = window.location.pathname.split("/")



let pageURL = urlArr[1].toLowerCase();

  
  switch(pageURL){
    case "home":
      ReactDOM.render(
        <React.StrictMode>
          <Home />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "career":
      ReactDOM.render(
        <React.StrictMode>
          <Career />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "duamahfil15082022":
      ReactDOM.render(
        <React.StrictMode>
          <DuaMahfil />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "reports":
      ReactDOM.render(
        <React.StrictMode>
          <Reports />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "dailymarketreviewLatest":
      ReactDOM.render(
        <React.StrictMode>
          <Dmrlatest name="Daily Market Review"/>
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "purchaseUnitasluf":
      ReactDOM.render(
        <React.StrictMode>
          <pUnitASLUF />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "purchaseUnitmtb":
      ReactDOM.render(
        <React.StrictMode>
          <pUnitMTB />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "whychoosebangladesh":
      ReactDOM.render(
        <React.StrictMode>
          <WhyChooseBangladesh />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "economyataglance":
      ReactDOM.render(
        <React.StrictMode>
          <EconomyAtAGlance />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "financialsystem":
      ReactDOM.render(
        <React.StrictMode>
          <FinancialSystem />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "portal":
      window.location ="https://portal.acaml.com"
    break;
    case "financialregulators":
      ReactDOM.render(
        <React.StrictMode>
          <FinancialRegulators />
        </React.StrictMode>,
        document.getElementById('root')
        );
 
    break;
    case "dmppage":
      ReactDOM.render(
        <React.StrictMode>
          <DPMPage/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "interviewsinmedia":
      ReactDOM.render(
        <React.StrictMode>
          <InterviewsInMedia/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "capage":
      ReactDOM.render(
        <React.StrictMode>
          <CAPage/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "nav":
      ReactDOM.render(
        <React.StrictMode>
          <NAVPage/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "dmr":
      ReactDOM.render(
        <React.StrictMode>
          <DmrReport report={urlArr[1]} prefixDate={urlArr[2]}/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "macroreports":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="Macro Reports" id="macroReports"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "companynotes":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="Company Notes" id="companyNotes"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "dailymarketreview":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="Daily Market Review" id="dailyMarketReview"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "securitiesmarket":
      ReactDOM.render(
        <React.StrictMode>
          <SecuritiesMarket/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "weeklyeconomicindicator":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="Weekly Economic Indicator" id="weeklyEconomicIndicator"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "alliancefortnightlyinshight":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="Alliance Fortnightly Insight" id="allianceFortnightlyInshight"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "iponotes":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="IPO Notes" id="ipoNotes"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "budget":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="Yearly Budget" id="budget"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "latestnews":
      ReactDOM.render(
        <React.StrictMode>
          <ReportPage name="Latest News" id="latestNews"/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "ir":
      ReactDOM.render(
        <React.StrictMode>
          <IReport />
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "mtbunitfund":
      ReactDOM.render(
        <React.StrictMode>
          <MTBUnitFund/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "whychooseacaml":
     
      ReactDOM.render(
        <React.StrictMode>
 <WhychooseAcaml/>      
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "managementprofile":
      ReactDOM.render(
        <React.StrictMode>
          <ManagementProfile/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "shajalmahamud":
      ReactDOM.render(
        <React.StrictMode>
          <ManagementProfile/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "forhadrahman":
      ReactDOM.render(
        <React.StrictMode>
          <ManagementProfile/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "ashiqrayhan":
      ReactDOM.render(
        <React.StrictMode>
          <ManagementProfile/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "khasadul":
      ReactDOM.render(
        <React.StrictMode>
          <ManagementProfile/>
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
    case "aadmina":
      ReactDOM.render(
        <React.StrictMode>
          <AdminPage/>
        </React.StrictMode>,
        document.getElementById('root')
      );
      break;
      case "alliancesandhanilifeunitfund":
        ReactDOM.render(
          <React.StrictMode>
            <AllianceSandhaniLifeUnitFund/>
          </React.StrictMode>,
          document.getElementById('root')
        );
      break;
      case "missionandvision":
        ReactDOM.render(
          <React.StrictMode>
            <MissionAndVision/>
          </React.StrictMode>,
          document.getElementById('root')
        );
      break;
      case "financialregulation":
        ReactDOM.render(
          <React.StrictMode>
            <FinancialRegulation/>
          </React.StrictMode>,
          document.getElementById('root')
        );
      break;
    default:
      ReactDOM.render(
        <React.StrictMode>
          <Home />
        </React.StrictMode>,
        document.getElementById('root')
      );
    break;
  }



