import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import {getTheNews, getTheBuckets,proxiedprice} from './firebaseC'
import { MDBCard, MDBContainer, MDBCardText, MDBCardBody, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import headbg from './headbg.jpg'
import ashiq from './Management/Ashiq.png'
import forhad from './Management/forhad.jpg'
import BG from './BG/bghu.jpg'
import MD from './Management/MD.jpg'
import shajal from './Management/shajal.jpg'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Select, InputNumber, Divider ,  Col, Row  , Image  } from 'antd';
import { Card,  } from 'antd';
import React from 'react'
import 'antd/dist/antd.css';
import cup0 from './Media M/cup0.jpg'
import cerImage0 from "./Media M/BSECAwardCertificate2021_page-0001.jpg"
function Home() {
 


  var w = window.innerWidth;

  window.onload=()=>{
 
    getTheNews()
    getTheBuckets()   
    document.querySelector("#ftnt").remove()
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

  let fundNameSelected = "None"
  function onChangeF(value) {
    
 fundNameSelected = value
  }
  function onChange(value) {
    proxiedprice( fundNameSelected , value )
  
  }
  const classes = useStyles();
  const { Option } = Select;

  const { Meta } = Card;

  return (
    <div className="App" style={{padding:"1%"}}>
   
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className='centered' style={{backgroundImage:`url(${BG})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", backgroundPosition:"10%", padding: "10%", height:"500px"}}>
      <Grid container spacing={3}>
      
        <Grid item xs={25} sm={10}>
          <h1 style={{color:"341"}} className="responsive64">
          </h1>

        </Grid>
     
   
      </Grid>
     
    </div>

      <MDBContainer fluid>
                
     <Divider  orientation="left" >News</Divider><div style={{borderBottom:" #ffcb05 5px solid", zIndex:-1}} id="scroll_news" >  
    
     <div id="scroll_news_A" style={{marginBottom:10+"px", zIndex:-1}}>
    
    </div>
     
      </div>
      </MDBContainer>
      <br></br>

      <center style={{padding:" 2%", backgroundColor:"#00000015"}}>
      
      <br></br>
    <h6><b>বিএসইসির ‘স্বাধীনতার সুবর্ণজয়ন্তী পুরস্কার’ পেলো অ্যালায়েন্স ক্যাপিটাল অ্যাসেট ম্যানেজমেন্ট লিমিটেড</b></h6>
    <Row>
      <Col span={12}>
        <p>পুঁজিবাজারের সেরা ১১টি মধ্যস্থতাকারী (ইন্টারমিডিয়ারিজ) প্রতিষ্ঠানকে ‘স্বাধীনতার সুবর্ণজয়ন্তী পুরস্কার’ দিয়েছে বাংলাদেশ সিকিউরিটিজ অ্যান্ড এক্সচেঞ্জ কমিশন (বিএসইসি)। গত বছরের কার্যক্রম পর্যালোচনার ভিত্তিতে তিন ক্যাটাগরিতে এ পুরস্কার দেওয়া হয়েছে। সোমবার (১০ অক্টোবর) রাতে রাজধানীর বঙ্গবন্ধু আন্তর্জাতিক সম্মেলন কেন্দ্রের (বিআইসিসি) কার্নিভাল হলে পুরস্কার বিতরণ করা হয়।</p>
        <p>এতে সম্পদ ব্যবস্থাপক (অ্যাসেট ম্যানেজমেন্ট) ক্যাটাগরিতে দ্বিতীয় হয়েছে অ্যালায়েন্স ক্যাপিটাল অ্যাসেট ম্যানেজমেন্ট লিমিটেড। বিএসইসি চেয়ারম্যান শিবলী রুবাইয়াত-উল-ইসলামের সভাপতিত্বে অনুষ্ঠানে প্রধান অথিতি ছিলেন স্থানীয় সরকার, পল্লী উন্নয়ন ও সমবায়মন্ত্রী তাজুল ইসলাম। বিশেষ অথিতি ছিলেন অর্থ মন্ত্রনালয়ের আর্থিক প্রতিষ্ঠান বিভাগের সচিব শেখ মোহাম্মদ সলীম উল্লাহ। অ্যালায়েন্স ক্যাপিটাল অ্যাসেট ম্যানেজমেন্ট লিমিটেডের পক্ষে পুরস্কার গ্রহণ করেন প্রতিষ্ঠানটির ম্যানেজিং ডিরেক্টর এন্ড সিইও খঃ আসাদুল ইসলাম। </p>
      </Col>
      <Col span={12}>
      <Image
    width={200}
    height={200}

    src={cup0}
  />
   <Image
    width={200}
    height={200}
    src={cerImage0}
  />
      </Col>
    </Row>
        </center>
      <br></br>
      <br></br>
      <br></br>
    <Divider><h3>NAV Performance</h3></Divider>
      <br></br>
      
      <MDBRow className='g-2 childSnap' style={{justifyContent:"center"}}>



      <MDBCol md='10'  id="navsection">
      <MDBCard  className="hoverBorder h-100">
      <MDBRow className='g-0'>
        
          <MDBCardBody>
            <MDBCardText>
       
              

              <div id="statBucket">

              </div>
            </MDBCardText>
            <MDBCardText>
            <small className='text-muted' >      <p id="validation"></p>
</small>
            </MDBCardText>
         

          </MDBCardBody>
     
    
      </MDBRow>
    </MDBCard>
    </MDBCol>
    <br></br>
    <br></br>
    </MDBRow>
    <br></br>
    <br></br>
    <br></br>
    <div className='centered' style={{backgroundImage:`url(${headbg})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
      <Grid container spacing={3}>
      
        <Grid item xs={12} sm={4}>
          <h1  className="responsive64">
          Today is your opportunity to build the tomorrow you want.
          </h1>
        </Grid>
        <Grid item xs={12} sm={8}>
      <Paper className={classes.paper} style={{backgroundColor:"#ffffff80", color:"#000000"}}>
        
      <form className={classes.root} id="standard-select-form" noValidate autoComplete="off"  >
      <div>
      <br></br>
       <br></br>
       
       <h1 >Invest in our Funds</h1>
       
      <br></br>
       <br></br>

  
   <center id="investsection">
     
   <Select
    id="standard-select-fund" 
    style={{ width: 200 }}
    placeholder="Select a Fund"
    optionFilterProp="children"
    onChange={onChangeF} 
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    <Option  value="MTB">MTB Unit Fund</Option>
    <Option value="ASLUF">Alliance Sandhani Life Unit Fund</Option>
  </Select>
   </center>

        <br></br>
        <br></br>
           
    
    
        <InputNumber style={{ width: 200 }} onChange={onChange}  type="number" placeholder="Number of Units" min={1} id="standard-select-unit"  />

       <br></br>
       <br></br>
      <p id="proxiedprice"></p>

       <br></br>
       <br></br>
       <br></br>
       <br></br>
      </div>

    </form>
      </Paper>
        </Grid>
   
      </Grid>
     
    </div>
      <br></br>
    <br></br>
    <br></br>
    <Divider><h3>Management Profile</h3></Divider>
    
    <br></br>


   <center>
   <MDBRow className='row-cols-1 row-cols-md-5 g-4 m-2 p-7 childSnap'style={{justifyContent:"center"}}>
      <MDBCol id="managementsection" style={{margin:"2%"}}>
         <Card style={{ width: w/6 }} cover={
      <img alt="example"  src={MD}/> }
    actions={[ <a href="khasadul">More</a>]} >
    <Meta title="Kh. Asadul Islam"  description="Managing Director & CEO" />
  </Card>
      </MDBCol >
     
      <MDBCol style={{margin:"2%"}}>
      
         <Card style={{ width: w/6 }} cover={
      <img alt="example"  src={ashiq}/> }
    actions={[ <a href="ashiqrayhan">More</a>]} >
    <Meta title="Ashiq Rayhan"  description="VP & Head of Research" />
  </Card>
              </MDBCol>
      <MDBCol style={{margin:"2%"}}>
       
         <Card style={{ width: w/6 }} cover={
      <img alt="example"  src={forhad}/> }
    actions={[ <a href="forhadrahman">More</a>]} >
    <Meta title="Md. Forhad Rahman"  description="AVP - Company Secretary & Head of Human Resource" />
  </Card>
      </MDBCol>
      <MDBCol style={{margin:"2%"}}>
      
         <Card style={{ width: w/6 }} cover={
      <img alt="example"  src={shajal}/> }
    actions={[ <a href="shajalmahamud">More</a>]} >
    <Meta title="Md. Shajal Mahamud"  description="Senior Manager-Accounts & Finance" />
  </Card>
      </MDBCol> 
    </MDBRow>
   </center>
   
  
    <br></br>
    <br></br>
    <Divider><h3>Interviews on Media</h3></Divider>

    <br></br>


    <MDBRow  id="IMsection" className='row-cols-1 row-cols-md-3 g-4 p-2  childSnap'style={{justifyContent:"center"}}>
     
      <MDBCol>  
        <MDBCard className="h-100 m-5" shadow="0" >
 <center>
 <iframe  width={ w/5} height={315} className="responsiveIFrame " src="https://www.youtube.com/embed/videoseries?list=PLvQJvXLYbyjJm71np6Bs2mxpRoXR_uNuG" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> 
 </center>
        </MDBCard>
      </MDBCol>

      <MDBCol>  
        <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe  width={ w/5} height={315} className="responsiveIFrame " src="https://www.youtube.com/embed/xlSGjLSp7T8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> 
</center>
        </MDBCard>
        </MDBCol>
        <MDBCol>  
   
        <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe  width={ w/5} height={315} className="responsiveIFrame " src="https://www.youtube.com/embed/A2RV8X41c8A" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
</center>
        </MDBCard>
        </MDBCol>
        <MDBCol>  
 
        <MDBCard className="h-100 m-5" shadow="0" >
      <center>
      <iframe  width={ w/5} height={315} className="responsiveIFrame " src="https://www.youtube.com/embed/KQzcxRj3b6M" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </center>  
        </MDBCard>
        </MDBCol>
        <MDBCol>  
    
        <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe  width={ w/5} height={315} className="responsiveIFrame " src="https://www.youtube.com/embed/wrdCo0xpTI4" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
</center>
        </MDBCard>
        </MDBCol>
        <MDBCol>  
   
        <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe  width={ w/5} height={315} className="responsiveIFrame " src="https://www.youtube.com/embed/cg1PwTM07aI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
</center>
        </MDBCard>
      </MDBCol>



    </MDBRow>

    </div>
  );

}  







export default Home;
