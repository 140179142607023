import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './Media M/1.jpg'
import bg2 from './Media M/2.jpg'
import bg3 from './Media M/3.jpg'
import bg4 from './Media M/4.jpg'
import bg5 from './Media M/5.jpg'
import { Image } from 'antd';

function DuaMahfil() {
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
  

<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<center>
<h2>Dua Mahfil Arranged by ACAML on August 15, 2022</h2>

<Image style={{padding:"10px"}} src={bg2} width={"19%"}/>
<Image style={{padding:"10px"}} src={bg3} width={"19%"}/>
<Image style={{padding:"10px"}} src={bg4} width={"19%"}/>
<Image style={{padding:"10px"}} src={bg5} width={"19%"}/>
<Image style={{padding:"10px"}} src={bg1} width={"19%"}/>
</center>

<hr></hr>
<p style={{textAlign:"justify"}}>
জাতির জনক বঙ্গবন্ধু শেখ মুজিবুর রহমানের ৪৭তম
শাহাদাৎ বার্ষিকীতে ‘জাতীয় শোক দিবস ২০২২’ উদযাপন
উপলক্ষ্যে জাতীয় কর্মসূচির আলোকে অ্যালায়েন্স
ক্যাপিটাল অ্যাসেট ম্যানেজমেন্ট লিমিটেডে এক আলোচনা
সভা ও দোয়া মাহফিলের আয়োজন করা হয়। উক্ত
মাহফিলে স্বাধীনতার মহান স্থপতি সর্বকালের
সর্বশ্রেষ্ঠ বাঙালী জাতির জনক বঙ্গবন্ধু শেখ মুজিবুর
রহমানের কর্মময় জীবনের ওপর বিশেষ আলোচনা ও
১৯৭৫ সালের ১৫ই আগষ্ট ঘাতকদের নির্মম বুলেটের
আঘাতে শাহাদাত বরণকারী জাতির জনক বঙ্গবন্ধু শেখ
মুজিবুর রহমান, বঙ্গমাতা বেগম ফজিলাতুন্নেসা মুজিবসহ
তার পরিবারের সকল সদস্য এবং অন্যান্য সকলের
আত্মার মাগফেরাত কামনা করে দোয়া করা হয়।
</p>
<p  style={{textAlign:"justify"}}>
On the event of the 47th Martyrdom Anniversary of
Father of the Nation Bangabandhu Sheikh Mujibur
Rahman and National Mourning Day 2022, a milad and dua
mahfil was organized at the premises of Alliance Capital
Asset Management Limited. Special discussion took place
on the endless contribution of the great architect of
independence of Bangladesh, Bangabandhu Sheikh
Mujibur Rahman, and prayers were sought for the souls
of the father of the nation Bangabandhu Sheikh Mujibur

Rahman, Bangamata Begum Fazilatunnessa Mujib, all his
family members and others who were martyred by the
merciless bullets of the assassins on 15th August, 1975.
</p>


  </div>

  );

}  







export default DuaMahfil;
