import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { getTheBuckets} from './firebaseC'
import { MDBCard, MDBContainer, MDBCardText, MDBCardBody, MDBRow, MDBCol } from 'mdb-react-ui-kit';

import BG from './BG/bghu.jpg'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Select, InputNumber, Divider   } from 'antd';
import { Card,  } from 'antd';
import React from 'react'
import 'antd/dist/antd.css';


function NAVPage() {
 


  var w = window.innerWidth;

  window.onload=()=>{
 
    getTheBuckets()   
    document.querySelector("#ftnt").remove()
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


  const classes = useStyles();
  const { Option } = Select;

  const { Meta } = Card;

  return (
    <div className="App" style={{padding:"1%"}}>
   
      <br></br>
      <br></br>
      <br></br>
      <br></br>
  
    <Divider><h3>NAV Performance</h3></Divider>
      <br></br>
      
      <MDBRow className='g-2 childSnap' style={{justifyContent:"center"}}>



      <MDBCol md='10'  id="navsection">
      <MDBCard  className="hoverBorder h-100">
      <MDBRow className='g-0'>
        
          <MDBCardBody>
            <MDBCardText>
       
              

              <div id="statBucket">

              </div>
            </MDBCardText>
            <MDBCardText>
            <small className='text-muted' >      <p id="validation"></p>
</small>
            </MDBCardText>
         

          </MDBCardBody>
     
    
      </MDBRow>
    </MDBCard>
    </MDBCol>
    <br></br>
    <br></br>
    </MDBRow>
    <br></br>
    <br></br>
    <br></br>
   

    </div>
  );

}  







export default NAVPage;
