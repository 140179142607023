import pic from './economyAtAGlance.jpg'





function EconomyAtAGlance(){

    window.onload=()=>{
          document.querySelector("#ftnt").remove()
        }
      return (
    <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1>Economy At A Glance</h1>
        <hr></hr>
        <center>
            <img alt="eCM at a glance" src={pic}/>
        </center>
    </div>
      );
    
}

export default EconomyAtAGlance