





function SecuritiesMarket(){

    window.onload=()=>{
          document.querySelector("#ftnt").remove()
        }
      return (
    <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1>Securities Market</h1>
        <hr></hr>
    
        <div class="vc_column-inner"><div class="wpb_wrapper">
	<div class="wpb_text_column wpb_content_element ">
		<div class="wpb_wrapper">
			<p>Bangladesh has two Stock Exchanges – Dhaka Stock Exchange (DSE) Limited, established in 1954 and Chittagong Stock Exchange (CSE) Limited, established in 1995. Both exchanges facilitate trading and investment through Computerized Automated Trading Platform while all records are maintained by the Central Depository Bangladesh Limited. Both exchanges are self-regulated, demutualized, and private sector entities and have their operating rules approved by the Bangladesh Securities and Exchange Commission (BSEC).</p>
<p><strong>Exchanges at a Glance</strong></p>
<ul>
<li>Dhaka Stock Exchange: Market Performance</li>
<li>Chittagong Stock Exchange: Market Performance</li>
</ul>
<p><strong>Market Performance</strong></p>
<ul>
<li>Dhaka Stock Exchange</li>
<li>Chittagong Stock Exchange</li>
</ul>
<p>Learn more on <a class="a_color" href="http://www.dsebd.org"  rel="noopener">Dhaka Stock Exchange</a> </p>

		</div>
	</div>
</div></div>
    </div>
      );
    
}

export default SecuritiesMarket