import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './careerBG.jpg'
function Career() {
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
    <div className='centeblack'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%",backgroundPosition:"10%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#ffffff",fontSize:"100px", textAlign:"right" ,fontWeight:"bold", width:"10%"}} className="responsive64">Career</h1>

    <center>

    <br></br>
    </center>
    </div>
<center>

<br></br>
<br></br>
<br></br>
<br></br>

<div style={{width:"40%", padding:"2%", border:"7px solid #ffcb05", borderRadius:"20px"}}>
<h1>
If you are passionate about embarking on a rewarding career in Asset Management and ready to thrive in a dynamic environment in which multi-tasking and time management are integral, send us your CV at,
</h1>
<br></br>
<br></br>
<h1 style={{color:"#FFCB05"}}>
    <a href="mailto:info@acaml.com">info@acaml.com.bd</a>
</h1>
</div>



</center>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

  </div>

  );

}  







export default Career;
