import { MDBCard, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './InterviewBG.jpg'
function InterviewsInMedia() {
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
    <div className='centeblack'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#ffffff",fontSize:"100px", textAlign:"right" ,fontWeight:"bold", width:"10%"}} className="responsive64">Interviews In Media</h1>

    <center>

    <br></br>
    </center>
    </div>
<center>
<MDBRow className='row-cols-1 row-cols-md-3 g-4 p-2  childSnap'style={{justifyContent:"center"}}>
     
     <MDBCol>  
       <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe width={460} height={315} src="https://www.youtube.com/embed/videoseries?list=PLvQJvXLYbyjJm71np6Bs2mxpRoXR_uNuG" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> 
</center>
       </MDBCard>
     </MDBCol>

     <MDBCol>  
       <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe width={460} height={315} src="https://www.youtube.com/embed/xlSGjLSp7T8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /> 
</center>
       </MDBCard>
       </MDBCol>
       <MDBCol>  
  
       <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe width={460} height={315} src="https://www.youtube.com/embed/A2RV8X41c8A" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
</center>
       </MDBCard>
       </MDBCol>
       <MDBCol>  

       <MDBCard className="h-100 m-5" shadow="0" >
     <center>
     <iframe width={460} height={315} src="https://www.youtube.com/embed/KQzcxRj3b6M" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
       </center>  
       </MDBCard>
       </MDBCol>
       <MDBCol>  
   
       <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe width={460} height={315} src="https://www.youtube.com/embed/wrdCo0xpTI4" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
</center>
       </MDBCard>
       </MDBCol>
       <MDBCol>  
  
       <MDBCard className="h-100 m-5" shadow="0" >
<center>
<iframe width={460} height={315} src="https://www.youtube.com/embed/cg1PwTM07aI" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
</center>
       </MDBCard>
     </MDBCol>



   </MDBRow>
</center>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

  </div>

  );

}  







export default InterviewsInMedia;
