import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { Layout, Menu, Breadcrumb } from 'antd';
import { UserOutlined, LaptopOutlined, FileOutlined, NotificationOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';
import React from 'react';
import { Form, Input,InputNumber, Button, Select, notification  } from 'antd';
import {navUpdateCon, newsUpdateCon, uploadFilePath, deleteDataIndCon, deleteDataCon, taskAssignCon} from './firebaseC';
import { Upload, Row, Col, Table  } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
function AkiAdmin() {

  const dataSource = [
    {
      key: '1',
      name: 'Md Forhad Rahman Ramim',
      department: "Human Resource",
      phone: "+8801722122104",
      extension: '106',
    },
    {
      key: '2',
      name: 'Md. A. Karim',
      department: "Information Technology",
      phone: "+8801715299622",
      extension: '110',
    },
    {
      key: '3',
      name: 'Md. Shajal Mahmud',
      department: "Finance and Accounce",
      phone: "+8801732609529",
      extension: '107',
    },
    {
      key: '4',
      name: 'Rehana Akter',
      department: "Finance and Accounce",
      phone: "+8801787899690",
      extension: '117',
    },
    {
      key: '7',
      name: 'Ashiq Rayhan',
      department: "Research",
      phone: "+880187248657",
      extension: '104',
    },
    {
      key: '8',
      name: 'Mehnaz Khaleel',
      department: "Research",
      phone: "+8801671161400",
      extension: '119',
    },
    {
      key: '5',
      name: 'Md. Riyad Hossain',
      department: "Human Resource",
      phone: "+8801700763198",
      extension: '109',
    },
    {
      key: '6',
      name: 'Mashrafi Shahriar',
      department: "Information and Technology",
      phone: "+8801961644541",
      extension: '126',
    },
  ];
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Extension',
      dataIndex: 'extension',
      key: 'extension',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
  ];
  
  const onUpdateNavFinish = (values) => {
    console.log('Success:', values);
    navUpdateCon(values)
  };
   
  const onUpdateNewsFinish = (values) => {
    console.log('Success:', values);
    newsUpdateCon(values)
  };
  const onTaskAssignFinish = (values) => {
    console.log('Success:', values);
    taskAssignCon(values)
  };

  const {  fileList } = false;
  const props = {

    beforeUpload: file => {
      this.setState(state => ({
        fileList: [...state.fileList, file],
      }));
      return false;
    },
    fileList,
  };



  function contentChange(contentNumber){
    switch(contentNumber){
      case 1:
        ReactDOM.render(
          <React.StrictMode>
               <Form
               id="navUpdateForm"
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 6 }}
        initialValues={{ remember: true }}
        onFinish={onUpdateNavFinish}
      >
        <h5>MTB Unit Fund</h5>
        <br></br>
        <Form.Item
          label="Sales Price "
          name="SPMTBV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Repurchase Price "
          name="RPMTBV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="NAV "
          name="NAVMTBV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Face Value "
          name="FVMTBV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber  value={10}  placeholder="10.00" style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Cost Price "
          name="CPMTBV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Market Price "
          name="MPMTBV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>

  
   <hr></hr>
   <h5>Alliance Sandhani Life Unit Fund</h5>
   <br></br>
        
    
        <Form.Item
          label="Sales Price "
          name="SPASLUFV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Repurchase Price "
          name="RPASLUFV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="NAV "
          name="NAVASLUFV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Face Value "
          name="FVASLUFV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber value={10}  placeholder="10.00" style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Cost Price "
          name="CPASLUFV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
        <Form.Item
          label="Market Price "
          name="MPASLUFV"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <InputNumber style={{ width: 200 }}  />
        </Form.Item>
<hr></hr>
        <Form.Item
          label="Validation Date"
          name="VALIDATION"
          rules={[{ required: true, message: 'Please input your Date!' }]}
        >
          <Input placeholder="eg. 10 January, 2021"/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="secondary" style={{margin:"10px"}} htmlType="reset">
            Clear
          </Button>
          <Button type="primary"style={{margin:"10px"}}  htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        
      </Form>
          </React.StrictMode>,
          document.getElementById('contentArea')
        );
      break;
          
      case 7: 
      ReactDOM.render(
        <React.StrictMode>
<Form
             id="assignTaskForm"
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 6 }}
      initialValues={{ remember: true }}
      onFinish={onTaskAssignFinish}
    >
      <h5>MTB Unit Fund</h5>
      <br></br>
      <Form.Item
        label="News Title"
        name="title"
        rules={[{ required: true, message: 'Please input your value!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="News Link"
        name="link"
        rules={[{ required: true, message: 'Please input your value!' }]}
      >
        <Input  />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
      
    </Form>
        </React.StrictMode>,
        document.getElementById('contentArea')
      )  
      break;

      case 2:
        ReactDOM.render(
          <React.StrictMode>
 <Form
               id="newsUpdateForm"
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 6 }}
        initialValues={{ remember: true }}
        onFinish={onUpdateNewsFinish}
      >
        <h5>MTB Unit Fund</h5>
        <br></br>
        <Form.Item
          label="News Title"
          name="title"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="News Link"
          name="link"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <Input  />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        
      </Form>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;

      case 5:
        ReactDOM.render(
          <React.StrictMode>
   <center>
   <Table dataSource={dataSource} columns={columns} size="default"/>

   </center>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 3:
        ReactDOM.render(
          <React.StrictMode>
            <h1>Latest News</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "Latest News")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 31:
        ReactDOM.render(
          <React.StrictMode>
            <h1>Weekly Economic Indicator</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "Weekly Economic Indicator")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 32:
        ReactDOM.render(
          <React.StrictMode>
            <h1>Alliance Fortnightly Insight</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "Alliance Fortnightly Insight")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 33:
        ReactDOM.render(
          <React.StrictMode>
            <h1>MTB Quarterly Portfolio Statement</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "MTB Quarterly Portfolio Statement")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 34:
        ReactDOM.render(
          <React.StrictMode>
            <h1>MTB Financial Statement</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "MTB Financial Statement")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 35:
        ReactDOM.render(
          <React.StrictMode>
            <h1>MTB Dividend Declaration</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "MTB Dividend Declaration")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 36:
        ReactDOM.render(
          <React.StrictMode>
            <h1>ASLUF Quarterly Portfolio Statement</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "ASLUF Quarterly Portfolio Statement")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 37:
        ReactDOM.render(
          <React.StrictMode>
            <h1>ASLUF Financial Statement</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "ASLUF Financial Statement")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 38:
        ReactDOM.render(
          <React.StrictMode>
            <h1>ASLUF Dividend Declaration</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "ASLUF Dividend Declaration")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 39:
        ReactDOM.render(
          <React.StrictMode>
            <h1>Yearly Budget</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "Yearly Budget")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 301:
        ReactDOM.render(
          <React.StrictMode>
            <h1>Macro Reports</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "Macro Reports")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 302:
        ReactDOM.render(
          <React.StrictMode>
            <h1>Daily Market Review</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "Daily Market Review")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 303:
        ReactDOM.render(
          <React.StrictMode>
            <h1>IPO Notes</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "IPO Notes")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 304:
        ReactDOM.render(
          <React.StrictMode>
            <h1>Company Notes</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "Company Notes")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 351:
        ReactDOM.render(
          <React.StrictMode>
            <h1>MTB Downloads</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "MTB Downloads")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      case 352:
        ReactDOM.render(
          <React.StrictMode>
            <h1>ASLUF Downloads</h1>
   <Upload maxCount={1} {...props} onChange={(file)=>{uploadFilePath(file, "ASLUF Downloads")}} style={{width:"200%"}}>
          <Button icon={<UploadOutlined />} style={{width:"200%", height:"200%"}}>Select File</Button>
        </Upload>
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;


      case 4:
        ReactDOM.render(
          <React.StrictMode>
             
     
    
  <Form
               id="deleteFileForm"
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 6 }}
        initialValues={{ remember: true }}
        onFinish={deleteDataCon}
      >
         <Form.Item
          label="Folder Name"
          name="fldn"
          rules={[{ required: true, message: 'Please input your file name!' }]}
        >
          <Select
    id="standard-select-folder" 
    style={{ width: 200 }}
    placeholder="Select a folder to index files"
    optionFilterProp="children"
    onChange={onChangeF} 
    filterOption={(input, option) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    <Option value="Latest News">Latest News</Option>
    <Option value="Macro Reports">Macro Reports</Option>
    <Option value="Daily Market Review">Daily Market Review</Option>
    <Option value="IPO Notes">IPO Notes</Option>
    <Option value="Company Notes">Company Notes</Option>
    <Option value="Weekly Economic Indicator">Weekly Economic Indicator</Option>
    <Option value="Alliance Fortnightly Insight">Alliance Fortnightly Insight</Option>
    <Option value="MTB Quarterly Portfolio Statement">MTB Quarterly Portfolio</Option>
    <Option value="MTB Financial Statement">MTB Financial Statement</Option>
    <Option value="MTB Dividend Declaration">MTB Dividend Declaration</Option>
    <Option value="MTB Downloads">MTB Downloads</Option>
    <Option value="ASLUF Quarterly Portfolio Statement">ASLUF Quarterly Portfolio</Option>
    <Option value="ASLUF Financial Statement">ASLUF Financial Statement</Option>
    <Option value="ASLUF Dividend Declaration">ASLUF Dividend Declaration</Option>
    <Option value="ASLUF Downloads">ASLUF Downloads</Option>
    <Option value="Budget">Budget</Option>
  </Select>  
        </Form.Item>
 
        
        <Form.Item
          label="File Name"
          name="fn"
          rules={[{ required: true, message: 'Please input your file name!' }]}
        >
          <Input   />
        </Form.Item>
       
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Delete
        </Button>
      </Form.Item>
      </Form>

       <br></br>
       <br></br>
     <ul id="filesIndexProp">

     </ul>

       <br></br>
       <br></br>
       <br></br>
       <br></br>

    
          </React.StrictMode>,
          document.getElementById('contentArea')
        )
        break;
      default:
        break;
    }

  }

        


  window.onload=()=>{
    document.querySelector("#ftnt").remove()
    document.querySelector("#floatingbtn").remove()
    // document.querySelector("#deleteFileForm").addEventListener("submit", (e)=>{
    //   // e.preventDefault()
    // })
  
  }


  const { Option } = Select;
  function onChangeF(folderNameSelected) {
    deleteDataIndCon(folderNameSelected  )
    
  }
  const { SubMenu } = Menu;
  const { Header, Content, Sider } = Layout;



  return (
      <div className="App">
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
        <div id="adminMain">  
        <Layout>
    <Header className="header">
      <div className="logo" />
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">Admin Area</Menu.Item>
        <Menu.Item key="2" disabled>Accounce Area [DEV]</Menu.Item>
        <Menu.Item key="3" disabled>Developer's Area [Restricted]</Menu.Item>
        <Menu.Item key="4" > <a  href='?AadminA'>Log Out</a></Menu.Item>
      </Menu>
    </Header>
    <Layout>
      <Sider width={200} className="site-layout-background">
        <Menu
          mode="inline"
          defaultOpenKeys={['sub1','sub2','sub3','sub4']}
          style={{ height: '100%', borderRight: 0 }}
        >
          <SubMenu key="sub1" icon={< LaptopOutlined />} title="Website">
            <Menu.Item key="1" onClick={()=>{contentChange(1)}}>NAV Update</Menu.Item>
            <Menu.Item key="2" onClick={()=>{contentChange(2)}}>News Update</Menu.Item>
            <Menu.Item key="4" onClick={()=>{contentChange(4)}}>Delete File</Menu.Item>
          </SubMenu>
          <SubMenu key="sub4" icon={<FileOutlined />} title="Upload Files">



          <Menu.Item key="3" onClick={()=>{contentChange(3)}}>Latest News </Menu.Item>
          <Menu.Item key="312" onClick={()=>{contentChange(301)}}>Macro Reports </Menu.Item>
          <Menu.Item key="313" onClick={()=>{contentChange(302)}}>Daily Market Review </Menu.Item>
          <Menu.Item key="314" onClick={()=>{contentChange(303)}}>IPO Notes </Menu.Item>
          <Menu.Item key="315" onClick={()=>{contentChange(304)}}>Company Notes </Menu.Item>
          <Menu.Item key="316" onClick={()=>{contentChange(31)}}>Weekly Economic Indicator </Menu.Item>
          <Menu.Item key="32" onClick={()=>{contentChange(32)}}>Alliance Fortnightly Insight </Menu.Item>
          <Menu.Item key="33" onClick={()=>{contentChange(33)}}>MTB: Quarterly Portfolio </Menu.Item>
          <Menu.Item key="34" onClick={()=>{contentChange(34)}}>MTB: Financial Statement </Menu.Item>
          <Menu.Item key="35" onClick={()=>{contentChange(35)}}>MTB: Dividend Declaration </Menu.Item>
          <Menu.Item key="351" onClick={()=>{contentChange(351)}}>MTB: Downloads </Menu.Item>
          <Menu.Item key="36" onClick={()=>{contentChange(36)}}>ASLUF: Quarterly Portfolio </Menu.Item>
          <Menu.Item key="37" onClick={()=>{contentChange(37)}}>ASLUF: Financial Statement </Menu.Item>
          <Menu.Item key="38" onClick={()=>{contentChange(38)}}>ASLUF: Dividend Declaration </Menu.Item>
          <Menu.Item key="352" onClick={()=>{contentChange(352)}}>ASLUF: Downloads </Menu.Item>
          <Menu.Item key="39" onClick={()=>{contentChange(39)}}>Budget </Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<UserOutlined />} title="Employee Management">
            <Menu.Item key="5" onClick={()=>{contentChange(5)}}>Employee List</Menu.Item>
            <Menu.Item key="7" onClick={()=>{contentChange(7)}}>Assign Task</Menu.Item>
            <Menu.Item key="8">Internal Notice</Menu.Item>
          </SubMenu>
          <SubMenu key="sub3" icon={<NotificationOutlined />} title="Investor">
            <Menu.Item key="9">Pending Purchase</Menu.Item>
            <Menu.Item key="10">Pending Surrender</Menu.Item>
            <Menu.Item key="11">Pending Transfer</Menu.Item>
            <Menu.Item key="12">Purchased List</Menu.Item>
            <Menu.Item key="13">Investor List</Menu.Item>
          </SubMenu>

        </Menu>
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
        </Breadcrumb>
        <Content className="site-layout-background" style={{ padding: 24,  margin: 0, minHeight: 280, }}>

        <Row>
      <Col span={12}>
      <div id="contentArea" >
     <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        <center>
        <h1>
             Navigate to use functions
          </h1>
        </center>
     </div>
        </Col>
      <Col span={12}>
      <div id="contentAreaConfirmation" >

</div>
      </Col>
    </Row>
  
     
        </Content>
      </Layout>
    </Layout>
  </Layout>
</div>
   
      </div>
  );

}  








export default AkiAdmin;
