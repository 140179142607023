import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import React from 'react';
import {Loginator} from './firebaseC';
import { Form, Input, Button } from 'antd';

export default function AdminPage() {
    
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  const onFinish = (values) => {
    Loginator(values.username, values.password)
  }
  return (
    <div className="App">
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
       <center>
       <div id="adminMain">  
      <div  style={{width:"40%"}}>
      <Form
        id="loginForm"
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <h1>Login</h1>
 <br></br>

      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>


      <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
      </div>
       
</div>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
 <br></br>
       </center>
   
      </div>
  );

}  





