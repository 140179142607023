
import React from 'react'
import 'antd/dist/antd.css';
import {   Divider , Skeleton  } from 'antd';
import {   viewPDF   } from './firebaseC';



window.onload=()=>{
 
    document.querySelector("#ftnt").remove()
  }

class ReportPage extends React.Component {
    render() {
      return(
<div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
<Divider> <h1> {this.props.name}</h1></Divider>

          
<div onLoad={viewPDF(this.props.name, this.props.id )} id={this.props.id}><Skeleton active /><Skeleton active /><Skeleton active /></div>

</div>
      )

    }
  }


  export default ReportPage