
import React from 'react'
import 'antd/dist/antd.css';
import {   Divider   } from 'antd';
import {   viewSpecPDF   } from './firebaseC';




window.onload=()=>{
 
    document.querySelector("#ftnt").remove()
  }

class DmrReport extends React.Component {
    render() {
      return(
<div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
<Divider> <h1>Daily Market Review Viewer</h1></Divider>

          
<div onLoad={viewSpecPDF(this.props.prefixDate, "dmrkrisp", 1, this.props.report)} id="dmrkrisp"></div>
    
</div>
      )

    }
  }


  export default DmrReport