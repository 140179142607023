

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { MDBCard,  MDBContainer,  MDBCardBody, MDBRow, MDBCol, MDBCardTitle } from 'mdb-react-ui-kit';
// Add the Firebase products that you want to use
import { getFirestore, doc, setDoc, deleteDoc  } from 'firebase/firestore';
import React from 'react';
import ReactDOM from 'react-dom';
import { collection,  getDocs, getDoc } from "firebase/firestore"; 
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { Line } from "react-chartjs-2";
import { getAuth, signInWithEmailAndPassword , signOut , updateProfile  } from "firebase/auth";
import AkiAdmin from './AkiAdmin.js'
import { getStorage, ref, uploadBytes,uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { Progress, notification ,Modal ,Result } from 'antd';
import { query, orderBy } from "firebase/firestore"; 
import { List, Button } from 'antd';
import { FilePdfTwoTone, DownloadOutlined, EyeOutlined  } from '@ant-design/icons';


var w = window.innerWidth;
var h = window.innerHeight;
const firebaseApp = initializeApp({
  apiKey: "AIzaSyDJ71ZeRbLmxpx30tTEUabdbDsMG8uGipE",
  authDomain: "acaml-login.firebaseapp.com",
  databaseURL: "https://acaml-login.firebaseio.com",
  projectId: "acaml-login",
  storageBucket: "acaml-login.appspot.com",
  messagingSenderId: "557030524334",
  appId: "1:557030524334:web:4f45dd8d3f0332b880343f",
  measurementId: "G-6NFMK1F3Y5"
});
 


const db = getFirestore();

const auth = getAuth();

export function getTheNews(){
  let newsRef = collection(db, "News");
  try {
    const doc =  getDocs(newsRef).then((doc)=>{
      document.querySelector(`#scroll_news_A`).innerHTML = `  ` 
    
      doc.forEach((docs)=>{
        document.querySelector(`#scroll_news_A`).innerHTML += `<li><a style="margin:0 40px ; font-size:16px; color:#ffcb05;" className="noIndex" href="${docs.data().link}">${docs.data().text}</a> </li> ` 
      })
    });
    console.log(doc)

  } catch (e) {
    console.log("Error getting cached document:", e)
    }
       }


  export function getTheBuckets(){
  
    let bucketRef = collection(db, "Fund");
    try {
      const doc =  getDocs(bucketRef).then((doc)=>{
    
        doc.forEach((doc)=>{
          if(doc.id === "SPChart"){


            class ChartsPageMTB extends React.Component {
              state = {
                dataBar: {
                  labels:  doc.data().DPMTBDATE,
                  datasets: [
                    {
                      label: "NAV as BDT",
                      data: doc.data().NAVMTB,
                      backgroundColor: [
                        "rgba(255, 134,159,0.4)",
                      ],
                      borderWidth: 2,
                      borderColor: [
                        "rgba(255, 134, 159, 1)", 
                      ]
                    }
                  ]
                },
                barChartOptions: {
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        barPercentage: 1,
                        gridLines: {
                          display: true,
                          color: "rgba(0, 0, 0, 0.1)"
                        }
                      }
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                          color: "rgba(0, 0, 0, 0.1)"
                        },
                        ticks: {
                          beginAtZero: true
                        }
                      }
                    ]
                  }
                }
              }
            
            
              render() {
                return (
                  <MDBContainer style={{maxHeight:"300px", marginBottom:"40px"}}>
                    <h3 className="mt-5">Fund Performance For MTB</h3>
                    <Line data={this.state.dataBar} options={this.state.barChartOptions} />
                  </MDBContainer>
                );
              }
            }


            class ChartsPageASLUF extends React.Component {
              state = {
                dataBar: {
                  labels: doc.data().DPASLUFDATE,
                  datasets: [
                    {
                      label: "NAV/BDT",
                      data: doc.data().NAVASLUF,
                      backgroundColor: [
                       
                        "#0000ff70",
                      ],
                      borderWidth: 2,
                      borderColor: [
                        "#0000ff90",
                      ]
                    }
                  ]
                },
                barChartOptions: {
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        barPercentage: 1,
                        gridLines: {
                          display: true,
                          color: "rgba(0, 0, 0, 0.1)"
                        }
                      }
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                          color: "rgba(0, 0, 0, 0.1)"
                        },
                        ticks: {
                          beginAtZero: true
                        }
                      }
                    ]
                  }
                }
              }
            
            
              render() {
                return (
                  <MDBContainer style={{maxHeight:"300px", marginBottom:"40px"}}>
                    <h3 className="mt-5">Fund Performance For ASLUF</h3>
                    <Line data={this.state.dataBar} options={this.state.barChartOptions} />
                  </MDBContainer>
                );
              }
            }
            
              
            if(document.getElementById('mtbChart')){
              ReactDOM.render(
                <ChartsPageMTB/>,
         document.getElementById('mtbChart')
       );
            }    
            if(document.getElementById('aslufChart')){
           
              ReactDOM.render(
                <ChartsPageASLUF/>,
         document.getElementById('aslufChart')
       );
            }    
         
         
          
          }else{
if(arguments[0] !== "fs"){
  document.querySelector("#validation").innerHTML = `(Valid From ${doc.data().VALIDFROM} to the announcement of the next NAV) <b></b>`
  // document.querySelector("#validation1").innerHTML = `(Valid From ${doc.data().VALIDFROM} to the announcement of the next NAV) <b></b>`
 
ReactDOM.render(
  <div>
    <MDBRow>
      <MDBCol sm='6'>
        <MDBCard  style={{borderRadius:"30px"}}  className=" shadow-5-strong" >
          <MDBCardBody>
            <MDBCardTitle>Sales Price</MDBCardTitle>
            <hr></hr>
            <MDBRow>
              <MDBCol size='2'>
              <i className="fas fa-chart-bar text-success fa-3x   me-4"></i>
              </MDBCol>
              <MDBCol size="10">
                <p>MTBUF: <b style={{float:"right"}}>{doc.data().SPMTB.toFixed(2)}</b></p>
                <p>ALSUF: <b style={{float:"right"}}>{doc.data().SPASLUF.toFixed(2)}</b></p>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol><br></br>
      <MDBCol sm='6'>
        <MDBCard  style={{borderRadius:"30px"}}  className=" shadow-5-strong" >
          <MDBCardBody>
            <MDBCardTitle>Repurchase Price</MDBCardTitle>
            <hr></hr>
            <MDBRow>
              <MDBCol size='2'>
              <i className="fas fa-chart-bar text-success fa-3x   me-4"></i>
              </MDBCol>
              <MDBCol size="10">
                <p>MTBUF: <b style={{float:"right"}}>{doc.data().RPMTB.toFixed(2)}</b></p>
                <p>ALSUF: <b style={{float:"right"}}>{doc.data().RPASLUF.toFixed(2)}</b></p>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol><br></br>
    </MDBRow><br></br><br></br>
    <MDBRow>
    <MDBCol sm='6'>
        <MDBCard  style={{borderRadius:"30px"}}  className=" shadow-5-strong" >
          <MDBCardBody>
            <MDBCardTitle>Face Value</MDBCardTitle>
            <hr></hr>
            <MDBRow>
              <MDBCol size='2'>
              <i className="fas fa-chart-pie text-warning fa-3x   me-4"></i>
              </MDBCol>
              <MDBCol size="10">
                <p>MTBUF: <b style={{float:"right"}}>{doc.data().FVMTB.toFixed(2)}</b></p>
                <p>ALSUF: <b style={{float:"right"}}>{doc.data().FVASLUF.toFixed(2)}</b></p>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol><br></br>
      <MDBCol sm='6'>
        <MDBCard  style={{borderRadius:"30px"}}  className=" shadow-5-strong" >
          <MDBCardBody>
            <MDBCardTitle>NAV</MDBCardTitle>
            <hr></hr>
            <MDBRow>
              <MDBCol size='2'>
              <i className="fas fa-chart-pie text-warning fa-3x   me-4"></i>
              </MDBCol>
              <MDBCol size="10">
                <p>MTBUF: <b style={{float:"right"}}>{doc.data().NAVMTB.toFixed(2)}</b></p>
                <p>ALSUF: <b style={{float:"right"}}>{doc.data().NAVASLUF.toFixed(2)}</b></p>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol><br></br>
    </MDBRow><br></br><br></br>
    <MDBRow>
    <MDBCol sm='6'>
        <MDBCard  style={{borderRadius:"30px"}}  className=" shadow-5-strong" >
          <MDBCardBody>
            <MDBCardTitle>Cost Price</MDBCardTitle>
            <hr></hr>
            <MDBRow>
              <MDBCol size='2'>
              <i className="fas fa-chart-line text-success fa-3x   me-4"></i>
              </MDBCol>
              <MDBCol size="10">
                <p>MTBUF: <b style={{float:"right"}}>{doc.data().NAVCOSTMTB.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></p>
                <p>ALSUF: <b style={{float:"right"}}>{doc.data().NAVCOSTASLUF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></p>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol><br></br>
      <MDBCol sm='6'>
        <MDBCard style={{borderRadius:"30px"}} className=" shadow-5-strong" >
          <MDBCardBody>
            <MDBCardTitle>Market Price</MDBCardTitle>
            <hr></hr>
            <MDBRow>
              <MDBCol size='2'>
              <i className="fas fa-chart-line text-success fa-3x   me-4"></i>
              </MDBCol>
              <MDBCol size="10">
                <p>MTBUF: <b style={{float:"right"}}>{doc.data().NAVMARKETMTB.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></p>
                <p>ALSUF: <b style={{float:"right"}}>{doc.data().NAVMARKETASLUF.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></p>
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
      </MDBCol><br></br>
    </MDBRow><br></br><br></br>
    <a href="?mtbunitfund">Learn More MTBUF</a><br></br>
    <a href="?alliancesandhanilifeunitfund">Learn More ASLUF</a>
    <p>Note: All the amounts above are in Bangladesh Taka</p>
  </div>, 
  document.querySelector("#statBucket")

)


}
        
          }
         
        })
      });
      console.log(doc)

    } catch (e) {
      console.log("Error getting cached document:", e)
      }
      }


export function GetNAVBucket(){


  let bucketRef = collection(db, "Fund");
  try {

    const doc =  getDocs(bucketRef).then((doc)=>{
      doc.forEach((doc)=>{
        if(doc.id === "SPChart"){
        }else{

          let SPMTB = doc.data().SPMTB
          let SPASLUF = doc.data().SPASLUF
          let RPMTB = doc.data().RPMTB
          let RPASLUF = doc.data().RPASLUF
          let NAVMTB = doc.data().NAVMTB
          let NAVASLUF = doc.data().NAVASLUF
          let VALIDFROM = doc.data().VALIDFROM
        
          document.querySelector("#MTBSP").innerHTML = ` <b>${SPMTB}</b> `
          document.querySelector("#ASP").innerHTML = `  <b>${SPASLUF}</b>`
          document.querySelector("#MTBRP").innerHTML = ` <b>${RPMTB}</b>`
          document.querySelector("#ARP").innerHTML = `  <b>${RPASLUF}</b>`
          document.querySelector("#MTBNAV").innerHTML = ` <b>${NAVMTB}</b>`
          document.querySelector("#ANAV").innerHTML = `  <b>${NAVASLUF}</b>`
          document.querySelector("#validation").innerHTML = `Sale and Repurchase Prices are valid From ${VALIDFROM} to the announcement of the next NAV) <b></b>`
        }
       
      })
    });

  } catch (e) {
    console.log("Error getting cached document:", e)
    }
  
}



export function Loginator(username, password){


let email = username+"@acaml.com.bd"
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
let dm = /^[A-Za-z0-9._%+-]+@acaml.com.bd$/g

    if(email.match(dm)){ 
      let bucketRef = doc(db, "Admin", email);
          
      try {
        const docA =  getDoc(bucketRef).then((doc)=>{
          console.log(doc.data())

              if(doc.data().admin === true){
                  
                const user = userCredential.user;
          
                if(user.displayName != null){
                  document.getElementById("loginLog").innerHTML="Logged In as: " + user.displayName
            
                }else{
                  updateProfile(auth.currentUser, {
                    displayName: username
                  }).then(() => {
                    document.getElementById("loginLog").innerHTML="Logged In as: " + user.displayName
            
                  })
                }
                  // Signed in 
             
          
              setTimeout(()=>{
                document.getElementById("loginForm").remove()
            
            ReactDOM.render(
              <React.StrictMode>
               <AkiAdmin/>
              </React.StrictMode>,
              document.getElementById('root')
            );
                }, 100)
             
                }else{
                  signOut(auth).then(() => {
                    document.getElementById("loginLog").innerHTML="Not an Admin" 

                  }).catch((error) => {
                    // An error happened.
                  });
                }
          
              
        })
        console.log(docA)
      }catch{
      }
      
    }else{
      signOut(auth).then(() => {
        document.getElementById("loginLog").innerHTML="Not a tenant of ACAML " 

      }).catch((error) => {
        // An error happened.
      });
    }
  
  })
  .catch((error) => {
    
    const errorCode = error.code;
    const errorMessage = error.message;
    document.getElementById("loginLog").innerHTML= errorCode+": "+errorMessage
    // ..
  });
}
export function taskAssignCon(values){

}
export function navUpdateCon(values){
document.querySelector("#navUpdateForm").setAttribute("hidden", "")
ReactDOM.render(
  <React.StrictMode>
   <div>
   <h2 style={{color:"red"}}>Are the informations Correct?</h2>

<p>Sales Price MTB : {values.SPMTBV}</p>
<p>Repurchase Price MTB : {values.RPMTBV}</p>
<p>NAV Price MTB : {values.NAVMTBV}</p>
<p>Face Value MTB : {values.FVMTBV}</p>
<p>Cost Price MTB : {values.CPMTBV}</p>
<p>Market Price MTB : {values.MPMTBV}</p>
<p>Sales Price ALSUF : {values.SPASLUFV}</p>
<p>Repurchase Price ALSUF : {values.RPASLUFV}</p>
<p>NAV Price ALSUF : {values.NAVASLUFV}</p>
<p>Face Value ALSUF : {values.FVASLUFV}</p>
<p>Cost Price ALSUF : {values.CPASLUFV}</p>
<p>Market Price ALSUF : {values.MPASLUFV}</p>
<p>Validation Date : {values.VALIDATION}</p>
<hr></hr>
<button className="btn btn-warning" onClick={()=>{navUpdate('denied')}}>No, Recorrect</button>
<br></br>
<br></br>
<button className="btn btn-success" onClick={()=>{navUpdate('confirm', values)}}>Yes</button>
   </div>
  </React.StrictMode>,
  document.getElementById('contentAreaConfirmation')
);

  
}

export function navUpdate(property, values){


  let LM =  values.NAVMTBV
  let LA =  values.NAVASLUFV

  if(property === "confirm"){
    setDoc(doc(db, "Fund", "Status"),{
      SPMTB: values.SPMTBV,
      SPASLUF: values.SPASLUFV,
      RPMTB: values.RPMTBV,
      RPASLUF: values.RPASLUFV,
      NAVMTB: values.NAVMTBV,
      NAVASLUF: values.NAVASLUFV,
      FVMTB: values.FVMTBV,
      FVASLUF: values.FVASLUFV,
      NAVCOSTMTB: values.CPMTBV,
      NAVCOSTASLUF: values.CPASLUFV,
      NAVMARKETMTB: values.MPMTBV,
      NAVMARKETASLUF: values.MPASLUFV,
      VALIDFROM: values.VALIDATION,
    }).then(()=>{
      document.getElementById("contentAreaConfirmation").innerHTML=`<iframe width="640px" height= "480px" src=  https://forms.office.com/Pages/ResponsePage.aspx?id=WdCRW9TeQEqY953r5ycVt9puyLijnzBFiKulmGB9CMBUNlgxR1VUWThRVVI2TVVRRU0wOU1JODZINC4u&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0" style= "border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe> `
         

      upFS(LM, LA, values.VALIDATION)
    });

  }
  if(property === "denied"){
document.querySelector("#navUpdateForm").removeAttribute("hidden", "")
document.querySelector("#contentAreaConfirmation").innerHTML = ``
  }

}

      
export function proxiedprice(fundNameSelected, UnitAmount){
  let bucketRef = collection(db, "Fund");
  try {
    const doc =  getDocs(bucketRef).then((doc)=>{
  
      doc.forEach((doc)=>{
        if(doc.id !== "SPChart"){

switch(fundNameSelected){
  case "MTB":
    document.querySelector("#proxiedprice").innerHTML = `You pay: <b>${(doc.data().SPMTB * UnitAmount).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") }</b>BDT`
    if((doc.data().SPMTB * UnitAmount) === 0){
      document.querySelector("#proxiedprice").innerHTML = ``
    }
  break;
  case "ASLUF":
    document.querySelector("#proxiedprice").innerHTML = `You pay: <b>${(doc.data().SPASLUF * UnitAmount).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") }</b>BDT `
    if((doc.data().SPMTB * UnitAmount) === 0){
      document.querySelector("#proxiedprice").innerHTML = ``
    }
  break;
  case "None":
    document.querySelector("#proxiedprice").innerHTML = `Please Select a Fund`
  break;
  default :
    document.querySelector("#proxiedprice").innerHTML = `Please Select a Fund`
  break;
}

        }
      })
      })
      console.log(doc)

    }catch (e) {
      console.log("Error getting cached document:", e)
      }
}






function upFS(LM, LA, date){
  

    let bucketRef = collection(db, "Fund");
    try {
      const doc =  getDocs(bucketRef).then((doc)=>{
    
        doc.forEach((doc)=>{
          if(doc.id === "SPChart"){
  

            let M1 = doc.data().NAVMTB[1]
            let M2 = doc.data().NAVMTB[2]
            let M3 = doc.data().NAVMTB[3]
            let M4 = doc.data().NAVMTB[4]
            //////////////////////
            let MD1 = doc.data().DPMTBDATE[1]
            let MD2 = doc.data().DPMTBDATE[2]
            let MD3 = doc.data().DPMTBDATE[3]
            let MD4 = doc.data().DPMTBDATE[4]
             
         
            let A1 = doc.data().NAVASLUF[1]
            let A2 = doc.data().NAVASLUF[2]
            let A3 = doc.data().NAVASLUF[3]
            let A4 = doc.data().NAVASLUF[4]
           ////////////////////////
            let AD1 = doc.data().DPASLUFDATE[1]
            let AD2 = doc.data().DPASLUFDATE[2]
            let AD3 = doc.data().DPASLUFDATE[3]
            let AD4 = doc.data().DPASLUFDATE[4]
      
         
       
            setChart(LM, LA, date, M1, M2, M3, M4, A1, A2, A3, A4, MD1, MD2, MD3, MD4, AD1, AD2, AD3, AD4)
          }
        })
        })
      console.log(doc)

      }catch (e) {
        console.log("Error getting cached document:", e)
        }
  }
  
  


function setChart(LM, LA, date, M1, M2, M3, M4, A1, A2, A3, A4, MD1, MD2, MD3, MD4, AD1, AD2, AD3, AD4){
  setDoc(doc(db, "Fund", "SPChart"),{
    NAVMTB: [M1, M2, M3, M4, LM, ],
    NAVASLUF: [A1, A2, A3, A4, LA,],
    DPMTBDATE:[MD1, MD2, MD3, MD4, date],
    DPASLUFDATE:[AD1, AD2, AD3, AD4, date],
  }).then(()=>{
    document.getElementById("contentAreaConfirmation").innerHTML="Chart Updated Successfully " 
        setTimeout( ()=>{
      document.getElementById("contentAreaConfirmation").innerHTML=""
    }, 4000)

    
  });
}

export function  newsUpdate(property, values){


  if(property === "confirm"){



    let bucketRef = collection(db, "News");
    try {
      const doc =  getDocs(bucketRef).then((doc)=>{
    let dLeng = doc.docs.length
        doc.forEach((doc)=>{
          console.log(dLeng)
    let lengwo = dLeng+1
      
          updateNewsMain(values, lengwo)


        })
        })
      console.log(doc)

      }catch{
      
      }
  }





  if(property === "denied"){
document.querySelector("#newsUpdateForm").removeAttribute("hidden", "")
document.querySelector("#contentAreaConfirmation").innerHTML = ``
  }

}     

export function updateNewsMain(values, lengwo){
 lengwo = lengwo.toString()
  setDoc(doc(db, "News",lengwo),{
    text: values.title,
    link: values.link
  }).then(()=>{
    document.getElementById("contentAreaConfirmation").innerHTML="News Updated Successfully " 
    ReactDOM.render(
      <React.StrictMode>
      </React.StrictMode>,
       document.getElementById('contentAreaConfirmation')
     );
setTimeout(()=>{
  ReactDOM.render(
    <React.StrictMode>
    </React.StrictMode>,
     document.getElementById('contentArea')
   );

}, 1000)

  });
}

export function newsUpdateCon(values){
  document.querySelector("#newsUpdateForm").setAttribute("hidden", "")
ReactDOM.render(
  <React.StrictMode>
   <div>
   <h2 style={{color:"red"}}>Are the informations Correct?</h2>

<p>Title: {values.title}</p>
<p>Link: {values.link}</p>
<hr></hr>
<button className="btn btn-warning" onClick={()=>{newsUpdate('denied',)}}>No, Recorrect</button>
<br></br>
<br></br>
<button className="btn btn-success" onClick={()=>{newsUpdate('confirm', values)}}>Yes</button>
   </div>
  </React.StrictMode>,
  document.getElementById('contentAreaConfirmation')
);
}

export function uploadFilePath(file, path){
  ReactDOM.render(
 <React.StrictMode>
 
  <p>Do you want to upload
    <h2 style={{color:"green"}}> {file.file.name}</h2>
     to 
     <h2 style={{color:"green"}}>{path}?</h2></p>
  
  <hr></hr>
<button className="btn btn-warning" onClick={()=>{uploadFileCon('denied',)}}>No</button>
<br></br>
<br></br>
<form id="datevalidationform">
  <p>Please specify the date of the file which you are uploading</p>
<input type="date" name="date" required></input>
<br></br>
<br></br>
<button className="btn btn-success" type='submit' onClick={()=>{submitred(file, path)}}>Yes</button>

</form>


 </React.StrictMode>,
  document.getElementById('contentAreaConfirmation')
);

}

function submitred(file, path){
    document.querySelector("#datevalidationform").addEventListener("submit", (e)=>{
      e.preventDefault()
        uploadFileCon('confirm', file, path, e.currentTarget.children[1].value)
      })
  


}
export function uploadFileCon(property, file, path, fileDate){

  ReactDOM.render(
    <React.StrictMode>
        <Progress
  strokeColor={{
    '0%': '#108ee9',
    '100%': '#87d068',
  }}
  percent={0}
/>
    </React.StrictMode>,
     document.getElementById('contentAreaConfirmation')
   );

  switch(property){
    case 'confirm': 
    
    uploadFile(file, path, fileDate)


    break;
    case 'confirmRN':
      uploadFile(file, path, fileDate)
    break;
    case 'denied':
    break;

    default:
      break;
  }
}
export function uploadFile(file, path, fileDate){
  let time = Date.now()
  const metadata = {
    contentType: 'application/pdf',
    dateUploaded: time
  };
const storage = getStorage();
const storageRef = ref(storage, `${path}/${file.file.name}` );
const uploadTask = uploadBytesResumable(storageRef, file.file, metadata);

// 'file' comes from the Blob or File API
uploadBytes(storageRef, file.file, metadata).then((snapshot) => {

  
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
     
     let lengwo = path
      setDoc(doc(db, lengwo, file.file.name),{
        Title: file.file.name,
        link: downloadURL,
        time: time
      }).then(()=>{
        Modal.success({
          content: 'File Uploaded Successfully',
        });
        if(lengwo === "Daily Market Review"){
          shortenURL(downloadURL, file.file.name, lengwo, fileDate)
          setTimeout(()=>{
            ReactDOM.render(
              <React.StrictMode>
               
     <iframe width="450" title="mailConf" height= "450" src= "https://forms.office.com/Pages/ResponsePage.aspx?id=WdCRW9TeQEqY953r5ycVt9puyLijnzBFiKulmGB9CMBUQlU3SVZRN1o2UUtWR0M5QzhMWlRDUkMwSi4u&embed=true" frameborder= "0" marginwidth= "0" marginheight= "0"  allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
              </React.StrictMode>,
               document.getElementById('contentArea')
             );
            
          }, 2000)




        }
       
     
    setTimeout(()=>{
      ReactDOM.render(
        <React.StrictMode>
        </React.StrictMode>,
         document.getElementById('contentArea')
       );
      
    }, 1000)
    
      });
      
    });
  
});

}

function shortenURL(url, name, lengwo, fileDate){
  let uri = url
  let upo = "dmr"+fileDate
  setDoc(doc(db, "shortenURlS", upo),{
    
    link: uri,
 
  }).then(()=>{
    Modal.success({
      content: 'File Link Shorted.',
    });
  })
}
export function viewPDF(folder, dest, maxNum){

const q = query(collection(db, folder),orderBy("time", "desc"));

    try {
   
      const doc =  getDocs(q).then((doc)=>{ 
   
        let both = []
     

        doc.forEach((doc)=>{
          var obj = {};
          obj["Title"] = doc.data().Title;
obj["Link"] = doc.data().link;
let timestamp = doc.data().time
let datest = new Date(timestamp);
obj["Time"] = datest
         both.push(obj) 
         
         
        }) 
        console.log(both)
          if(maxNum === 1){
            document.querySelector(`#${dest}`).innerHTML =`
            `
              document.querySelector(`#${dest}`).innerHTML +=`
             <center>
             <iframe width="${w-100}px" height="${h-100}px" src="${doc.docs[0].data().link}"></iframe>
             </center>
              `
            
          }else{
              
                ReactDOM.render(
                <List
                  itemLayout="horizontal"
                  dataSource={both}
                  style={{margin:"2%"}}
                  renderItem={item => (
                    <List.Item
                    
                    extra={
                      <div>
                 

                        <a style={{margin:"10px"}}  href={item.Link}>         <Button style={{margin:"10px"}} shape="circle" icon={<EyeOutlined />} size={20} />
                      </a>
                        <a style={{margin:"10px"}} download href={item.Link}> <Button type="primary"   shape="circle" icon={<DownloadOutlined />} size={20} />
                        </a>
                      </div>
                    }>
                      <List.Item.Meta
                      size="large"
                        avatar={<FilePdfTwoTone />}
                        title={<a href={item.Link}>{item.Title}</a>}
                        description={`${item.Time}`}
                      />
                      
                    </List.Item>
                  )}
                />,
                document.querySelector(`#${dest}`)
              );
          
          }
        
         
 
      });

    } catch (e) {
      console.log("Error getting cached document:", e)
      }
}
export function viewSpecPDF(date, dest, maxNum, reportName){

let t = `${reportName+date}`

try {
    let ref = doc(db, "shortenURlS", t)
   let docu = getDoc(ref).then((doc)=>{
if(doc.data()){
  ReactDOM.render(
    <iframe src={doc.data().link} style={{width: window.innerWidth - 10 + "px",  height: window.innerHeight}}></iframe>,
    document.querySelector(`#${dest}`)
  );
}else{
  ReactDOM.render(
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the file you are trying to access does not exist."
      extra={
        <Button type="primary" href="reports">Try browsing the other reports</Button>
      }
    />,
    document.querySelector(`#${dest}`)
  );
}
 
  });

} catch (e) {
  ReactDOM.render(
    <Result
      status="404"
      title="404ss"
      subTitle="Sorry, the file you are trying to access does not exist."
      // extra={<Button type="primary">Back Home</Button>}
    />,
    document.querySelector(`#${dest}`)
  );
  }

     
 
  
}


export function deleteDataIndCon(folder){
  let bucketRef = collection(db, folder);
    try {
      const doc =  getDocs(bucketRef).then((doc)=>{
        doc.forEach((doc)=>{

          document.querySelector("#filesIndexProp").innerHTML +=`
          <li style="list-style:none; border:3px solid #343a40; padding:10px; margin:15px;"><a href="${doc.data().link}">File Name: ${doc.data().Title}</a></li>
          `
         
        })
      });
      console.log(doc)

    } catch (e) {
      console.log("Error getting cached document:", e)
      }

}

export function deleteDataCon(values){
console.log(values.fn)
console.log(values.fldn)

ReactDOM.render(
  <React.StrictMode>

   <p>Do you want to Delete
     <h2 style={{color:"Red"}}> {values.fn}</h2>
      from 
      <h2 style={{color:"Red"}}>{values.fldn}?</h2></p>

   <hr></hr>
 <button className="btn btn-warning" onClick={()=>{deleteFileMain('denied',)}}>No</button>
 <br></br>
 <br></br>
 <button className="btn btn-success" onClick={()=>{deleteFileMain('confirm', values)}}>Yes</button>
 
 
  </React.StrictMode>,
   document.getElementById('contentAreaConfirmation')
 );
}

export function deleteFileMain(property, values){

  const storage = getStorage();

switch(property){
  case 'confirm':
// Create a reference to the file to delete
const desertRef = ref(storage, `${values.fldn}/${values.fn}`);

// Delete the file


deleteObject(desertRef).then(() => {
  deleteDoc(doc(db,values.fldn , values.fn)).then(() => {
    document.getElementById("contentAreaConfirmation").innerHTML="File Deleted Successfully " 
    document.querySelector("#filesIndexProp").innerHTML =``
    ReactDOM.render(
      <React.StrictMode>
       <Result
    status="success"
    title="Successfully Deleted The File"
  /> 
      </React.StrictMode>,
       document.getElementById('contentAreaConfirmation')
     );
setTimeout(()=>{
  ReactDOM.render(
      <p>-</p>,
     document.getElementById('contentArea')
   );

}, 1000)
      


  });

}).catch((error) => {
  document.getElementById("contentAreaConfirmation").innerHTML="File Delete Failed. Error: "+error 

});
  break;
  case 'denied':
  document.getElementById("contentAreaConfirmation").innerHTML="File Delete Cancelled by User " 
  break;
  default:
    break;
  
}

}

getSearchResults()
export function getSearchResults(s){
 let q =  query(collection(db, "ASLUF Dividend Declaration"),orderBy("time", "desc"))
 try {

  const doc =  getDocs(q).then((doc)=>{ 
  // console.log(doc.docs)
    
     

  });

} catch (e) {
  console.log("Error getting cached document:", e)
  }
}

