import './App.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './BG/bg2.jpg'
import { Divider , Skeleton  } from 'antd';
import { getTheBuckets} from './firebaseC'

import {  viewPDF , GetNAVBucket  } from './firebaseC'
import logoBig from './logoBig.svg';
function AllianceSandhaniLifeUnitFund() {
 
    window.onload=()=>{
      getTheBuckets("fs")   

      viewPDF("ASLUF Downloads", "aslufD")
      viewPDF("ASLUF Financial Statement", "aslufFS")
      viewPDF("ASLUF Dividend Declaration", "aslufDD")
      viewPDF("ASLUF Quarterly Portfolio Statement", "aslufQPS")
      GetNAVBucket()
      document.querySelector("#ftnt").remove()
    }
  return (
    <div className="App" style={{padding:"1%"}}>
    <br></br><br></br>
<br></br>
<div className='centered'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10% 15%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#343a40", textAlign:"left" ,fontWeight:"bold", width:"70%"}} className="responsive64">Alliance Sandhani Life Unit Fund</h1>

    <center>

    {/* <img src={down} width="10%" className="hoverDown"/> */}

    <br></br>
    </center>
    </div>
      <div style={{padding:"20px"}}>      
<br></br>
<h2>
  About
</h2>
<h5 style={{textAlign: "justify", lineHeight:"2"}}>
Mutual Fund industry, especially open-end mutual fund, is growing exponentially and gaining popularity among individual and institutional investors as it provides the benefits of efficiently structured and diversified investments with minimal risk that are managed by professional and experienced investment managers.

In the beginning of 2019, Alliance Capital Asset Management Limited (ACAML) is trusted to manage a fund named ‘Alliance Sandhani Life Unit Fund’ (formerly known as Sandhani Life Unit Fund) considering the proven successful track record in fund management. This is the first time in Bangladesh that a mutual fund is assigned to a new asset manager for managing its affairs.

Alliance Capital Asset Management Ltd is the current Asset Manager of this fund where Bangladesh General Insurance Company Limited (BGIC) is the Trustee and BRAC Bank Limited is the Custodian of the fund. M/S Hoda Vasi Chowdhury & Co. Chartered Accountants is the current auditor of the Fund.

Recently, the Asset Under Management (AUM) of the fund has stood at BDT 350 million and we are expecting that it will go up to BDT 400 million by this year.</h5>
<br></br>
<h2>
Fund Details
</h2>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Sponsor: Sandhani Life Unit Fund
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Trustee: Bangladesh General Insurance Company Limited (BGIC)
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Custodian: BRAC Bank Limited
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Asset Manager: Alliance Capital Asset Management Limited (ACAML)
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Nature: Open-end Mutual Fund
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Size of Fund: Taka 50 crore 
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Unit Price and Market Lot: Tk. 10.00 per unit, 500 units per lot
</h5>
<h5 style={{textAlign: "justify", lineHeight:"1"}}>
Auditor: Hoda Vasi Chowdhury & Co
</h5>
<br></br>
<br></br>
<br></br>

<Divider><h3>NAV</h3></Divider>
<div>
        <center>
          <div style={{fontFamily: 'garamond'}}>
            <div style={{backgroundColor: '#ffcb05', padding: '10px'}}>
              <h3>Alliance Capital Asset Management Ltd (ACAML)</h3>
            </div>
            NAV of the Unit Funds Managed by ACAML
            <table style={{textAlign:"center", width: '100%', fontSize:"large"}}>
              <tbody><tr style={{border:"1px solid #ffcb05"}}>
                  <th style={{border:"1px solid #ffcb05"}}>
                    Name of the Fund
                  </th>
                  <th style={{border:"1px solid #ffcb05"}}>
                    NAV/Unit
                  </th>
                  <th style={{border:"1px solid #ffcb05"}}>
                    Sale Price/Unit
                  </th>
                  <th>
                    Repurchase Price/Unit
                  </th>
                </tr>
                <tr style={{border:"1px solid #ffcb05"}}>
                  <td>MTB Unit Fund</td>
                  <td style={{border:"1px solid #ffcb05"}} id="MTBNAV" />
                  <td style={{border:"1px solid #ffcb05"}} id="MTBSP" />
                  <td style={{border:"1px solid #ffcb05"}} id="MTBRP" />
                </tr>
                <tr style={{border:"1px solid #ffcb05"}}>
                  <td>Alliance Sandhani Life Unit Fund*</td>
                  <td style={{border:"1px solid #ffcb05"}} id="ANAV" />
                  <td style={{border:"1px solid #ffcb05"}} id="ASP" />
                  <td  style={{border:"1px solid #ffcb05"}}id="ARP" />
                </tr>
                <tr />
              </tbody></table>
            <p>*This fund is previously known as 'Sandhani Life Unit Fund'. The new name of the fund has come into effect from january 30, 2019 after the change of AMC approved by the BSEC</p>
            <p id="validation" />
          </div>
        </center>
        <div className="row">
          <div className="col-md-5">
            <img src={logoBig} width="40%" alt="" />
          </div>
          <div className="col-md-5" style={{marginLeft: '160px'}}>
            <div className="row">
              <div className="col-md-4">    <div style={{width: 'auto', height: '100%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
                </div>
              </div>
              <div className="col">www.acaml.com</div>
              <div className="col">  <div style={{width: '13px', height: '100%', backgroundColor: '#ffcb05', margin: '5px', WebkitTransform: 'skewX(-10deg)', MozTransform: 'skewX(-10deg)', msTransform: 'skewX(-10deg)', transform: 'skewX(-25deg)'}}>
                </div></div>
            </div>
            <br />
            <p>T: +88 02 9121360 | E: info@acaml.com</p>
          </div>
        </div>
      </div>
<br></br>
<br></br>
<br></br>

<div id="aslufChart"  style={{height:"auto", transform:"scale(1.0)"}}><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<Divider><h3>Downloads</h3></Divider>
<div id="aslufD"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>

<br></br>
<br></br>

<Divider><h3>Financial Statements</h3></Divider>
<div id="aslufFS"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
<Divider><h3>Dividend Declaraion</h3></Divider>
<div id="aslufDD"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
<Divider><h3>Quarterly Portfolio Statement</h3></Divider>
<div id="aslufQPS"><Skeleton active /><Skeleton active /><Skeleton active /></div>
<br></br>
<br></br>
   
      </div>
    </div>
  );

}  







export default AllianceSandhaniLifeUnitFund;
