





function WhyChooseBangladesh(){

    window.onload=()=>{
          document.querySelector("#ftnt").remove()
        }
      return (
    <div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1>Why Choose Bangladesh</h1>
        <hr></hr>
    
        <div class="wpb_wrapper">
  <p>Bangladesh as termed as one of the emerging economies of the next decade as has been listed as one of the N11 countries by J P Morgan in 2012. With robust growth and expansion of the overall economy especially productive sectors, this offers an excellent environment for foreign investors. Here is why you should choose Bangladesh for investing.</p>
<p><strong>Foreign Portfolio Investors</strong><br></br>
Inefficient markets provide greater opportunity for higher gains. Compared to any other emerging financial markets, Bangladesh is still providing untapped opportunities of superior returns for portfolio investors. <a href="https://old.acaml.com/wp-content/uploads/2017/08/faq_sp.pdf" target="_blink"> Learn Why and How to Begin with Bangladesh Securities Market.</a></p>

</div>
    </div>
      );
    
}

export default WhyChooseBangladesh