import './App.css';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import bg1 from './corporateBG.jpg'
import ca1 from './opacity/ca1.png'
import ca2 from './opacity/ca2.png'
import ca3 from './opacity/ca3.png'
import ca4 from './opacity/ca4.png'
import down from './down.svg'

function DPMPage() {
 
  window.onload=()=>{
    document.querySelector("#ftnt").remove()
  }

  return (


    <div className="App" style={{padding:"1%"}}>
      
    <div className='centered'style={{backgroundImage:`url(${bg1})`, backgroundRepeat:"no-repeat", backgroundSize:"100%", padding: "10%", height:"auto"}}>
    <br></br>
    <br></br>
    <h1 style={{color:"#ffcb05", textAlign:"right" ,fontWeight:"bold", width:"10%"}} className="responsive64">Corporate Advisory</h1>

    <center>

    <img src={down} width="10%" alt="ImageAltedLoading" className="hoverDown"/>

    <br></br>
    </center>
    </div>
    
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
 <center>
 <h3 style={{textAlign:"center", width:"80%", lineHeight:2}} >ACAML’s Corporate Advisory business provides classical strategic and tactical advice and unparalleled execution to public and private companies and financial sponsors across a broad range of industry sectors. Our success begins and ends with an unstinting commitment to delivering world-class client service.

Our approach in all situations is first and always rooted in serving the client’s best interests, which also means advising a client not to pursue a transaction when it does not enhance their strategic objectives or measure-up to their standards.</h3>
 
 </center>
 
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
<div className='centered' id="hknws"  style={{backgroundImage:`url(${ca1})`, backgroundColor:"#ffcb0550",backgroundRepeat:"no-repeat", backgroundPosition:"10%",backgroundSize:"100%",  padding: "10%", height:"auto"}}>

    <center>
    <h1 style={{color:"#343a40" ,fontWeight:"bold", width:"60%"}} className="responsive64">Merger  & Acquisition</h1>

    </center>
    </div>


<br></br>
<br></br>
<h3 style={{textAlign: "justify", lineHeight:"2"}}>
We serve as an independent, objective advisor in financing situations. At ACAML, we help companies raise funds using various financial instruments such as term-loan, 

syndicated loan in both local and foreign currency, project and infrastructure finance, asset/mortgage backed securitization etc. Our Services:

</h3>
<ul>
  <li>
    <h5>
    Syndicated Finance
    </h5>
  </li>
  <li>
    <h5>
Project & Infrastructure Finance
    </h5>
  </li>
  <li>
    <h5>
Asset/Mortgage Backed Securitizat
    </h5>
  </li>
</ul>

<br></br>
<br></br>


<div className='centered' id="hknws"  style={{backgroundImage:`url(${ca2})`, backgroundColor:"#ffcb0550",backgroundRepeat:"no-repeat", backgroundPosition:"10%",backgroundSize:"100%",  padding: "10%", height:"auto"}}>

    <center>
    <h1 style={{color:"#343a40" ,fontWeight:"bold", width:"60%"}} className="responsive64">Divestitures & Sale Transactions
</h1>

    </center>
    </div>


<br></br>
<br></br>
<h3 style={{textAlign: "justify", lineHeight:"2"}}>
In advising companies on the acquisition of a company or set of assets, we evaluate potential acquisition targets, provide valuation analyses, and evaluate and propose 

financial and strategic alternatives. We also advise as to the timing, structure, financing, and pricing of a proposed acquisition, and assist in negotiating and closing the deal. 

Our approach:

</h3>
<ul>
  <li>
    <h5>
Target Identification/Buyer Identification
    </h5>
  </li>
  <li>
    <h5>
      Valuation of Target/Equity
    </h5>
  </li>
  <li>
    <h5>
      Due diligence of Target/Negotiation with Buyer
    </h5>
  </li>
  <li>
    <h5>
    End-to-end Coordination
    </h5>
  </li>
  <li>
    <h5>
    Financing options for the Transaction
    </h5>
  </li>
</ul>
<br></br>
<br></br>







<div className='centered' id="hknws"  style={{backgroundImage:`url(${ca3})`, backgroundColor:"#ffcb0550",backgroundRepeat:"no-repeat", backgroundPosition:"10%",backgroundSize:"100%",  padding: "10%", height:"auto"}}>

    <center>
    <h1 style={{color:"#343a40" ,fontWeight:"bold", width:"60%"}} className="responsive64">Structured Finance

</h1>

    </center>
    </div>


<br></br>
<br></br>
<h3 style={{textAlign: "justify", lineHeight:"2"}}>
We advise clients contemplating the sale of certain businesses, assets, or their entire company by evaluating and recommending financial and strategic alternatives with 

respect to the sale, advising on the appropriate sales process for the situation and valuation issues, and preparing an offering memorandum and other appropriate sales 

materials. We also identify and contact qualified acquirers and assist in negotiating and closing the sale. Our approach:

</h3>

<ul>
  <li>
    <h5>
Target Identification/Buyer Identification
    </h5>
  </li>
  <li>
    <h5>
      Valuation of Target/Equity
    </h5>
  </li>
  <li>
    <h5>
      Due diligence of Target/Negotiation with Buyer
    </h5>
  </li>
  <li>
    <h5>
    End-to-end Coordination
    </h5>
  </li>
  <li>
    <h5>
    Financing options for the Transaction
    </h5>
  </li>
</ul>

<br></br>
<br></br>
<div className='centered' id="hknws"  style={{backgroundImage:`url(${ca4})`, backgroundColor:"#ffcb0550",backgroundRepeat:"no-repeat", backgroundPosition:"10%",backgroundSize:"100%",  padding: "10%", height:"auto"}}>

    <center>
    <h1 style={{color:"#343a40" ,fontWeight:"bold", width:"60%"}} className="responsive64">Joint Venture
</h1>

    </center>
    </div>


<br></br>
<br></br>
<h3 style={{textAlign: "justify", lineHeight:"2"}}>
We advise clients on joint venture deal structuring and negotiations – from identifying potential partners to pressure-testing term sheets and deal concepts and other

regulatory & legal activities.Our market intelligence and relationship with a large number of corporate clients allow us to assist foreign and/or large local companies in 

finding the right Joint-Venture (JV) partner in Bangladesh. Our approach:

</h3>

<ul>
  <li>
    <h5>
    Understanding the desired criteria for partner selection

    </h5>
  </li>
  <li>
    <h5>
    Identify potential partners

    </h5>
  </li>
  <li>
    <h5>
    Short-list the best prospects by analyzing compatibility

    </h5>
  </li>
  <li>
    <h5>
    Developing models for equity valuation for the Short-listed firms

    </h5>
  </li>
  <li>
    <h5>
    Arranging required Financing(debt/equity)

    </h5>
  </li>
  <li>
    <h5>
    Negotiation for the client


    </h5>
  </li>
  <li>
    <h5>
    Coordinating regulatory & legal activities


    </h5>
  </li>
</ul>








  </div>

  );

}  







export default DPMPage;
